import { getBase64 } from '../../../../reduxLoop/helpers/documentHelpers';
import * as NOTIFICATION_ACTIONS from '../../../../reduxLoop/actions/notificationActions';

class imageSelectionModal {
  constructor($ngRedux, $scope, searchApi) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.searchApi = searchApi;

    this.resourcePickerAuthorTemplate = require('../../../../screen/optionTemplates/authorOption.html');
    this.searchParameters = {
      expand: 'SUMMARY',
      types: 'PERSON,CVO,SCHOOL,SCHOOLCOMMUNITY,CLB,ORGANISATION,BOARDING,GOVERNINGINSTITUTION',
      highlight: 'false'
    };
  }

  hasError() {
    if (!this.image.name) {
      this.$ngRedux.dispatch(
        NOTIFICATION_ACTIONS.addNotificationAction({
          type: 'ERROR',
          message: 'Afbeelding moet ingevuld zijn',
        })
      );
      return true;
    }
    return false;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close(this.image);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  $onInit() {
    this.image = this.resolve.modalData.image || {};
  }

  async imageChanged(scope, event, data) {
    let base64 = await getBase64(data.file);
    scope.ctrl.image = {
      ...scope.ctrl.image,
      name: data.file.name,
      size: data.file.size,
      $$base64: base64,
      file: data.file
    };
  }

  author2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return resource.firstName + ' ' + resource.lastName;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }

      if (resource.title) {
        return resource.title;
      }
    }
    return '<NONE>';
  }
}


export default {
  template: require('./imageSelectionModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: imageSelectionModal
};
