import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import { createLRUSelector, keySelectorHref } from '@newStore/documentApi/documentApiHelpers';
import {
  selectApiWithPendingChanges,
  selectDocumentRoot,
  selectHasChildren,
  selectPathToRootHrefs,
  selectWebPagePerContentMap,
} from '@newStore/documentApi/documentApiSelectors';
import { createTypedSelector } from '@newStore/genericHelpers';
import {
  FilteredBuildingBlock,
  FilteredEditComponent,
  NodeType,
  NodeTypeConfigApplied,
  isHiddenNodeConfig,
  isGenericRootNodeConfig,
  isRootNodeConfig,
  EditComponent,
} from '@nodeTypeConfig/configTypes';
import { getGenericNodeType, getNodeType, getNodeTypeConfig } from '@nodeTypeConfig/index';
import { lruMemoize } from '@reduxjs/toolkit';
import { isEqual, omit } from 'lodash';
import { createCachedSelector } from 're-reselect';
import { shallowEqual } from 'react-redux';
import { proAccessRightsComponent } from '@nodeTypeConfig/PRO/proGenericConfigProps';
import { ancestorTypeFilter } from './nodeTypeConfigHelpers';

export const selectDocumentRootType = createTypedSelector(
  [
    (state) => selectDocumentRoot(state),
    (state) =>
      state.documentUI.currentDocument
        ? selectWebPagePerContentMap(state)?.[state.documentUI.currentDocument]
        : undefined,
  ],
  (root, webPage) => {
    if (!root || Object.keys(root).length === 0) {
      // some weird bug, perhaps in reselect, where root becomes an empty object instead of null.
      return null;
    }
    return getNodeType(root, webPage, null);
  }
);

export const selectGenericDocumentRootType = createTypedSelector(
  [
    (state) =>
      state.documentUI.currentDocument &&
      state.documentApi.content[state.documentUI.currentDocument],
  ],
  (item) => {
    if (!item) {
      return null;
    }
    return getGenericNodeType(item);
  }
);

export const selectGenericDocumentRootConfig = createTypedSelector(
  [selectGenericDocumentRootType],
  (type) => {
    if (!type) {
      return null;
    }
    const nodeTypeConfig = getNodeTypeConfig(type);
    if (!isGenericRootNodeConfig(nodeTypeConfig)) {
      // can not happen this will always be a Non Extended RootNodeConfig
      // TODO remove if AppliedNodeConfig is Typed better with making distinction between Root, BuildingBLock, Hidden
      return null;
    }
    return nodeTypeConfig;
  }
);

export const selectAllNodeTypesMap = createLRUSelector(
  [
    (state) => selectApiWithPendingChanges(state).content,
    (state) => selectWebPagePerContentMap(state),
    (state) => selectDocumentRootType(state),
  ],
  (contentMap, webPageMap, documentType): Record<ContentHref, NodeType> => {
    return Object.fromEntries(
      Object.entries(contentMap).flatMap(([href, content]) => {
        let nodeType: NodeType;
        if (contentMap[href]) {
          nodeType = getNodeType(content, webPageMap[href], documentType);
        } else {
          // this can happen when:
          // - a global document is added to a download group. it temporarily has the relation without the content
          return [];
        }
        return [[href, nodeType]];
      })
    );
  },
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  }
);

export const selectNodeType = (state, href) => selectAllNodeTypesMap(state)[href];

/**
 * [PERF] selectAppliedNodeConfig has been called 201530 times. Total time spent: 392.20000000298023 ms.
   [PERF] selectAppliedNodeConfig Average time spent per call: 0.0019461122413684327 ms. Median time: 0 ms. Max time: 5.899999998509884 ms.
 */

export const selectAppliedNodeConfig = createCachedSelector(
  [
    (state, href: ContentHref) => selectNodeType(state, href),
    (state, href) => selectPathToRootHrefs(state, href),
    (state) => selectAllNodeTypesMap(state),
    (state, href) => selectHasChildren(state, href),
    (state) => selectDocumentRootType(state),
  ],
  (nodeType, pathToRootHrefs, allNodeTypesMap, hasChildren, rootType): NodeTypeConfigApplied => {
    const rootConfig = rootType && getNodeTypeConfig(rootType);

    const fullConfig = getNodeTypeConfig(nodeType);

    // we use the audienceTab of the config, if it is defined, otherwise, use the default which is the root.
    const audienceTab =
      ('audienceTab' in fullConfig && fullConfig.audienceTab) ||
      (rootConfig && 'audienceTab' in rootConfig && rootConfig.audienceTab);

    if (isHiddenNodeConfig(fullConfig)) {
      // hidden nodes do not have edit or buildingBlocks and are never extended.
      return { ...fullConfig, edit: [], buildingBlocks: [] };
    }

    const filteredComponents = fullConfig.edit
      .filter((c) => ancestorTypeFilter(c, pathToRootHrefs, allNodeTypesMap))
      // TODO remove this second filter when aside is refactored and these components are removed from the edit (they should be in audienceTab)
      .filter(
        (c) =>
          c.component !== 'coverage' &&
          c.component !== 'namedSet' &&
          c.component !== 'acccessRights'
      );

    const newEdit = filteredComponents.map((item) => {
      const filtered: FilteredEditComponent = omit(item, [
        'showInAncestorTypes',
        'hideInAncestorTypes',
        'blacklist',
        'whitelist',
      ]);
      return filtered;
    });

    const filteredBuildingBlocks =
      fullConfig.buildingBlocks?.filter((c) => {
        return ancestorTypeFilter(c, pathToRootHrefs, allNodeTypesMap);
      }) || [];

    const newBuildingBlocks = filteredBuildingBlocks.map((item) => {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        showInAncestorTypes,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        hideInAncestorTypes,
        ...rest
      } = item;
      return rest;
    }) as Array<FilteredBuildingBlock>;

    const filteredConfig: NodeTypeConfigApplied = {
      ...fullConfig,
      edit: newEdit,
      buildingBlocks: newBuildingBlocks,
    };

    if (audienceTab) {
      if (fullConfig.addAccessRightsToAudienceTab) {
        const accessRightsComponent =
          typeof fullConfig.addAccessRightsToAudienceTab === 'object'
            ? {
                ...proAccessRightsComponent,
                ...fullConfig.addAccessRightsToAudienceTab,
              }
            : proAccessRightsComponent;
        filteredConfig.audienceTab = [...audienceTab, accessRightsComponent];
      } else {
        filteredConfig.audienceTab = audienceTab;
      }
    }

    return filteredConfig;
  },
  {
    memoize: lruMemoize,
    argsMemoize: lruMemoize,
    memoizeOptions: {
      resultEqualityCheck: isEqual,
      // (a, b) => {
      //   const res = shallowEqual(a.edit, b.edit);
      //   return res;
      // },
    },
  }
)(keySelectorHref);

// [PERF] Average time spent per call: 0.0033956613586068603 ms.
// [PERF] Average time spent per call: 0.0034193167688135804 ms.

// with LRU
// [PERF] Average time spent per call: 0.0022269005671806553 ms.
// [PERF] Average time spent per call: 0.002253639944860298 ms.

export const selectTypeNameSingle = (state: RootState, href: ContentHref) => {
  return selectAppliedNodeConfig(state, href).information.single;
};

export const selectEditConfigForNode = (
  state: RootState,
  href: ContentHref
): FilteredEditComponent[] => {
  return selectAppliedNodeConfig(state, href).edit;
};

export const selectBuildingBlocksForNode = (
  state: RootState,
  href: ContentHref
): FilteredBuildingBlock[] => {
  return selectAppliedNodeConfig(state, href).buildingBlocks || [];
};

export const selectAppliedDocumentRootConfig = (state: RootState) => {
  const href = state.documentUI.currentDocument;
  if (!href) {
    return null;
  }
  const nodeTypeConfig = selectAppliedNodeConfig(state, href);
  if (!isRootNodeConfig(nodeTypeConfig)) {
    // will never happen TODO remove if AppliedNodeConfig is Typed better with making distinction between Root, BuildingBLock, Hidden
    return null;
  }
  return nodeTypeConfig;
};

export const selectFieldEditConfigForNode = (
  state: RootState,
  href: ContentHref,
  property: string
): EditComponent | undefined => {
  return selectAppliedNodeConfig(state, href).edit.find((c) => c.property === property);
};
