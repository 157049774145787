import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

require('./submitSuggestions.scss');

export class submitSuggestionsModal {
  constructor($scope, $ngRedux, $uibModalInstance, $location) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$uibModalInstance = $uibModalInstance;
    this.$location = $location;
  }

  async $onInit() {
    this.change = (text) => {
      // key pressed
      this.message = text;
    };
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.document.viewModel.submitSuggestionsModalOpen) {
        this.$uibModalInstance.close();
      }

      return {
        saveAttempted: state.document.viewModel.submittingSuggestions,
        countSuggestionsToSubmit:
          state.document.viewModel.suggestions.countSuggestionsToSubmitSelected,
        message: state.document.viewModel.submitSuggestionsMessage,
        otherCreators: state.document.viewModel.suggestions.otherCreatorsInSuggestionsToSubmit,
        groupedProposalsToSubmitByAuthor:
          state.document.viewModel.suggestions.groupedProposalsToSubmitByAuthor,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  submit() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.submitSuggestionsAction(this.message, this.$location.absUrl())
    );
  }

  cancel() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.closeSubmitSuggestionsModalAction());
  }



  toggleGroupSelection(group) {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.toggleSubmitSuggestionsOfGroupAction(group, group.selected)
    );
  }
}
