import {
  attachmentTypeConversionMap,
  attachmentDocumentTypes,
} from '@newStore/documentUI/transformContent/attachmentHelpers';
import { compactTitle } from '../../../reduxLoop/helpers/documentHelpers';

class rowAttachment {
  constructor($scope, $ngRedux, constants, Utils) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.constants = constants;
    this.utils = Utils;
    this.documentTypes = attachmentDocumentTypes;
  }

  $onInit() {
  }

  $onChanges() {
    // eslint-disable-next-line no-nested-ternary
    this.attachment = this.sItem.$$attachments && this.sItem.$$attachments.has('ATTACHMENT')
      ? this.sItem.$$attachments.get('ATTACHMENT').original
      : (this.sItem.attachments ? this.sItem.attachments.find(a => a.type === 'CONTENT') : null);
  }

  iconSrc() {
    if (this.attachment) {
      const contentType = this.attachment.contentType;
      const icon = attachmentTypeConversionMap[contentType]
        ? attachmentTypeConversionMap[contentType].icon
        : null;

      if (icon !== '') {
        return icon;
      }
      return 'img/icons/file.svg';
    }

    return '';
  }

  showAttachmentTitle() {
    return this.sItem.title || this.sItem.description;
  }

  showAttachmentDescription() {
    return this.sItem.description;
  }

  removeExtensionFromName() {
    if (this.attachment) {
      if (this.attachment.description && this.attachment.description.trim() !== '') {
        return compactTitle(this.attachment.description);
      }

      let name = this.attachment.name;

      if (name.length > 42) {
        return name.substring(0, 39) + '...';
      }

      return name;
    }

    return '';
  }

  showFileType() {
    if (this.attachment) {
      const contentType = this.attachment.contentType;
      const label = attachmentTypeConversionMap[contentType]
        ? attachmentTypeConversionMap[contentType].name
        : null;

      if (label !== '') {
        return label;
      }

      if (contentType.length > 25) {
        return contentType.substring(0, 23) + '...';
      }

      return contentType;
    }

    return '';
  }

  showFileCreateDate() {
    if (this.attachment) {
      let creationDate;

      if (this.attachment.$$meta && this.attachment.$$meta.created) {
        creationDate = new Date(this.attachment.$$meta.created);
      }

      if (creationDate === undefined) {
        return '-';
      }

      return this.utils.timeToString(creationDate);
    }

    return '';
  }

  showAttachmentFileSize() {
    if (!this.attachment) {
      return '-';
    }

    let sizeInBytes = this.attachment.size;

    if (sizeInBytes === undefined) {
      return '-';
    }

    if (sizeInBytes >= 1048576) {
      let sizeInMB = Number(sizeInBytes / 1024 / 1024);
      sizeInMB = sizeInMB.toFixed(0);
      return sizeInMB + ' MB';
    }

    let sizeInKB = Number(sizeInBytes / 1024);
    sizeInKB = sizeInKB.toFixed(0);
    return sizeInKB + ' KB';
  }

  showFileTag() {
    if (this.sItem.tags && this.sItem.tags.length > 0) {
      let value = '';
      this.sItem.tags.forEach(tag => {
        const attachType = this.documentTypes.find(t => t.value === tag);
        if (attachType) {
          value = attachType.display;
        }
      });
      return value;
    }
    return '-';
  }
}

export const rowAttachmentComponent = {
  template: require('./rowAttachment.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<?',
    sIndex: '<?'
  },
  controller: rowAttachment
};
