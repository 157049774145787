import { parse, stringify } from 'himalaya';
import uuidv4 from 'uuid/v4';
import { getNewReadOrder, sanitizeHTML } from './documentHelpers';

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  var i;
  for (i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export const readFileInputEventAsArrayBuffer = (event) => {
  return new Promise((resolve) => {
    let file = event.target.files[0];
    let reader = new FileReader();

    reader.onload = (loadEvent) => {
      var arrayBuffer = loadEvent.target.result;
      resolve({
        name: event.target.files[0].name,
        arrayBuffer: arrayBuffer
      });
    };

    reader.onprogress = (data) => {
      if (data.lengthComputable) {
        const progress = parseInt(data.loaded / data.total * 100, 10);
        console.log('PROGRESS: ' + progress);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

const jsonToHtml = (json) => sanitizeHTML(stringify(json), 'html');

export const documentAsDocx = async (docx, rootDocument, startingPosition = 0) => {
  const mammoth = require('mammoth'); //eslint-disable-line

  const mammothResult = await mammoth.convertToHtml({ arrayBuffer: docx }, {
    styleMap: [
      'p[style-name=\'Title\'] => title',
      'p[style-name=\'Subtitle\'] => description',
      'p[style-name=\'heading 5\'] => h5',
      'p[style-name=\'heading 6\'] => h6'
    ]
  });

  // The generated HTML
  const html = mammothResult.value;

  // Parse HTML to json
  let jsonFromHtml = parse(html);

  // Look if there is a title
  const indexOfTitle = jsonFromHtml.findIndex(o => o.tagName === 'title');
  if (indexOfTitle > -1) {
    rootDocument.title = jsonToHtml(jsonFromHtml[indexOfTitle].children);
    jsonFromHtml = jsonFromHtml.splice(indexOfTitle + 1);
  }

  // Look if there is a description
  const indexOfDescription = jsonFromHtml.findIndex(o => o.tagName === 'description');
  if (indexOfDescription > -1) {
    rootDocument.description = jsonToHtml(jsonFromHtml[indexOfDescription].children);
    jsonFromHtml = jsonFromHtml.splice(indexOfDescription + 1);
  }

  function handleNextItem(nextItems, array) {
    if (nextItems.length > 0) {
      const nextItem = nextItems[0];
      const itemsUnder = nextItems.slice(1, nextItems.length);
      if (/^h[0-9]*$/.test(nextItem.tagName)) {
        let nextWithSameTagIndex = itemsUnder.findIndex(e => e.tagName === nextItem.tagName);
        if (nextWithSameTagIndex === -1) {
          nextWithSameTagIndex = itemsUnder.length;
        }
        const items = [];
        handleNextItem(itemsUnder.splice(0, nextWithSameTagIndex), items);
        array.push({
          key: uuidv4(),
          type: 'SECTION',
          title: jsonToHtml(nextItem.children),
          items: items
        });
      } else if (nextItem.tagName === 'p') {
        // check if has image
        const imgIndex = nextItem.children.findIndex(e => e.tagName === 'img');
        if (imgIndex > -1) {
          const allImages = nextItem.children.filter(e => e.tagName === 'img').length === nextItem.children.length;
          if (!allImages) {
            const image = nextItem.children.splice(imgIndex, 1)[0];
            const imageSrc = image.attributes.filter(o => o.key === 'src')[0].value;
            // paragraph has illustration
            array.push({
              key: uuidv4(),
              type: 'PARAGRAPH',
              text: jsonToHtml(nextItem.children),
              src: imageSrc
            });
          } else {
            // this is a standalone image!
            nextItem.children.reverse().filter(e => e.tagName === 'img').forEach(img => {
              const src = img.attributes.filter(o => o.key === 'src')[0].value;
              array.push({
                key: uuidv4(),
                type: 'IMAGE',
                src: src
              });
            });
          }
        } else {
          // no image in paragraph
          const text = jsonToHtml(nextItem.children);
          if (text) {
            array.push({
              key: uuidv4(),
              type: 'PARAGRAPH',
              text,
            });
          }
        }
      } else if (nextItem.tagName === 'ol' || nextItem.tagName === 'ul') {
        const prev = array.slice(-1)[0];
        if (prev && prev.type === 'PARAGRAPH') {
          // add to previous, if previous is a paragraph
          prev.text = prev.text
            + '<' + nextItem.tagName + '>'
            + jsonToHtml(nextItem.children)
            + '</' + nextItem.tagName + '>';
        } else {
          // create new paragraph if previous is not a paragraph
          array.push({
            key: uuidv4(),
            type: 'PARAGRAPH',
            text: '<' + nextItem.tagName + '>' + jsonToHtml(nextItem.children) + '</' + nextItem.tagName + '>'
          });
        }
      }
      if (itemsUnder.length > 0) {
        handleNextItem(itemsUnder, array);
      }
    }
    return array;
  }

  // const batch = contentApi.createBatch();
  function flattenTree(tree) {
    const result = {
      content: [],
      relations: [],
      attachments: []
    };

    function addItems(items, parent, positionOffset) {
      items.forEach((item, i) => {
        const relationKey = uuidv4();
        const contentItem = Object.assign({}, item, {
          importance: 'MEDIUM', attachments: [], parentKey: parent.key, relationKey
        });
        if (item.text || item.src) { // ILLUSTRATION needs to have a CONTENT node too in content-api
          contentItem.attachments.push({
            key: item.key,
            text: item.text || '',
            type: 'CONTENT',
            contentType: 'text/html',
            name: 'content.html'
          });
          contentItem.$$html = item.text;
          delete contentItem.text;
        }
        result.content.push(contentItem); 
        const readorder = positionOffset
          ? positionOffset.previousReadOrder + (i + 1) * positionOffset.incrementGap
          : i + 1;
        let relation = {
          key: relationKey,
          from: {
            href: '/content/' + item.key
          },
          to: {
            href: '/content/' + parent.key
          },
          relationtype: 'IS_PART_OF',
          readorder
        };
        result.relations.push(relation);

        if (item.src) {
          let file = dataURItoBlob(item.src);
          const extension = require('mime').getExtension(file.type);
          file.name = 'imported_illustration.' + extension;

          result.attachments.push({
            resourceKey: item.key,
            key: uuidv4(),
            file: file,
            type: 'ILLUSTRATION',
            $$base64: item.src,
          });
          delete item.src;
        }
        if (item.items) {
          addItems(item.items, item);
        }
      });
    }

    if (tree) {
      // if (tree.$$meta) {
      //   batch.put('/content/' + tree.key, Object.assign({}, tree, { importance: 'MEDIUM', attachments: [] }));
      // }
      if (tree.items) {
        const positionOffset = getNewReadOrder(startingPosition, rootDocument.$$children, tree.items.length);
        addItems(tree.items, tree, positionOffset);
      }
    }
    return result;
  }

  const array = [];
  handleNextItem(jsonFromHtml, array);
  rootDocument.items = array;
  const result = flattenTree(rootDocument);

  return { resources: result, attachmentsToUpload: result.attachments.filter(o => o.type === 'ILLUSTRATION') };
};
