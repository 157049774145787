import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
// this is "smart" component

class References {
  constructor(ConfirmationService, constants, ModalWindowService, $scope, $ngRedux) {
    'ngInject';

    this.constants = constants;
    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.loading = true;
    this.references = [];
    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  async newChild() {
    const opts = {
      component: 'llinkidReferenceModal',
      dataForModal: {
        parameters: {
          type: 'THEME',
          root: this.referenceFrame.key,
          orderBy: 'title'
        },
        typeTranslation: this.referenceFrame.title,
        type: this.sConfig.options.referenceFrame,
        edit: {},
        alreadySelectedReferences: this.references.map(r => r.from.$$expanded)
      }
    };

    const selectedReference = await this.modalWindowService.open(opts);

    if (selectedReference) {
      // save relation to reference frame item
      const newRelation = {
        relationtype: 'REQUIRES',
        strength: selectedReference.strength,
        from: {
          href: selectedReference.$$meta.permalink
        },
        to: {
          href: '/content/' + this.sDocument.key
        }
      };
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation));
    }
  }

  async edit(relation) {
    const opts = {
      component: 'llinkidReferenceModal',
      dataForModal: {
        parameters: {
          type: 'THEME',
          root: this.referenceFrame.key,
          orderBy: 'title'
        },
        typeTranslation: this.referenceFrame.title,
        type: this.sConfig.options.referenceFrame,
        edit: {
          strength: relation.strength,
          relation: relation
        },
        alreadySelectedReferences: this.references.map(r => r.from.$$expanded)
      }
    };

    const editedReference = await this.modalWindowService.open(opts);

    if (editedReference) {
      // update relation to reference frame item
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchRelationAction(
        relation.key,
        {
          from: { href: editedReference.$$meta.permalink },
          strength: editedReference.strength
        }
      ));
    }
  }

  async delete(deletedRelation) {
    let confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(deletedRelation.key));
    }
  }


  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      let referenceRelations = (state.document.apiWithPendingChanges.contentRelations.to['/content/' + this.sDocument.key] || [])
        .filter(relation => relation.relationtype === 'REQUIRES');

      let themes = state.document.llinkidThemeReferences[this.sConfig.component];

      if (themes) {
        referenceRelations = referenceRelations
          .filter(rel => themes.find(t => t.$$meta.permalink === rel.from.href))
          .map(rel => {
            // find relation from.href in possible theme ref opts and fill from.$$expanded if found
            const theme =  themes.find(t => t.$$meta.permalink === rel.from.href);
            return {...rel, from: {...rel.from, $$expanded: theme}};
          });
      }

      return {
        references: referenceRelations,
        loading: themes === undefined,
        readOnly: state.document.viewModel.isReadOnlyDocument
      };
    })(this);

    if (!this.sConfig.options.maxReferences) {
      this.sConfig.options.maxReferences = 999;
    }

    this.referenceFrame = this.constants.llinkidReferenceFrames
      .filter(rf => rf.type === this.sConfig.options.referenceFrame)[0];

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.initLlinkidThemeReferencesAction(
      this.sConfig.component
    ));
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./references.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<', // parent resource of references
    sConfig: '<'
  },
  controller: References
};
