/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { accessRights } from '../../../reduxLoop/helpers/accessRights';
import { escapeRegExp } from 'lodash';
require('./asideAccessRights.scss');

class asideAccessRights {
  constructor($scope, $ngRedux, $translate) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.accessRightsGroups = Object.values(accessRights);

    this.translationService = $translate;
    this.displayTranslatedAccessRights = (item) => {
      return this.translationService.instant(item.translationKey);
    };
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const isRoot = state.document.viewModel.aside.rootDocument.key === this.sDocument.key;
      const isReadOnlyFromConfig = this.sConfig.options && this.sConfig.options.readOnly;
      return {
        ...state.document.viewModel.aside,
        accessRightsGroups: this.accessRightsGroups,
        selectedAccessRights: state.document.viewModel.aside.editDocument.selectedAccessRights,
        isInconsistent: state.document.viewModel.aside.editDocument.isAccessRightsInconsistent,
        isUsed: state.document.isIncludedInProTheme,
        isReadOnly: state.document.viewModel.aside.isReadOnly || isReadOnlyFromConfig,
        editKey: state.document.viewModel.aside.editDocument.key,
        isGlobalDocument: state.document.viewModel.aside.editDocument.$$type === 'GLOBAL_DOCUMENT',
        isInherited: !isRoot && !state.document.viewModel.aside.editDocument[this.sConfig.options.field],
        isRoot
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(e, model) {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.editKey, {
      [this.sConfig.options.field]: model.namedsets
    }));
  }

  filterData(data, str) {
    if (str.length === 0) return data;
    return data.filter((elm) => {
      const regExp = new RegExp(escapeRegExp(str), 'gi');
      return regExp.test(elm.title) || regExp.test(elm.name);
    });
  }

  backToInherited() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.editKey, {
      [this.sConfig.options.field]: undefined
    }));
  }
}

export default {
  template: require('./asideAccessRights.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideAccessRights
};
