import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { cloneDeep } from 'lodash';

class asideSources {
  constructor(ModalWindowService, ConfirmationService, $ngRedux) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;

    this.$ngRedux = $ngRedux;
  }

  async addSource() {
    const response = await this.modalWindowService.open({ component: 'sourceModal' });

    if (response) {
      // save relation to goal item
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addNodeToParentNodeAction(this.sDocument.key, 1, 'SOURCE', { title: response.title }));
    }
  }

  async delete(relation) {
    let confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeNodeAction(relation.from.$$expanded.key));
    }
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      let relations = (state.document.apiWithPendingChanges.contentRelations.to['/content/' + this.sDocument.key] || [])
        .reduce((result, relation) => {
          let content = state.document.apiWithPendingChanges.content.get(relation.from.href);
          if (relation.relationtype === 'IS_PART_OF' && content && content.type === 'SOURCE') {
            const copyOfRelation = cloneDeep(relation);
            copyOfRelation.from.$$expanded = content;
            result.push(copyOfRelation);
          }
          return result;
        }, []);

      return {
        sources: relations,
        loading: false
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.expandRelationsToAction(
      this.sDocument.key
    ));
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./asideSources.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: asideSources
};
