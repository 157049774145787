import { RootState } from '@generalTypes/rootStateTypes';
import { pathMap } from '@newStore/externalData/externalDataState';
import { createTypedSelector, mapRelationsToRelationsToAndFrom } from '@newStore/genericHelpers';
import { selectAllReferenceFrameData } from '@newStore/referenceFrames/referenceFramesSelectors';
import { getPathToRoot } from './externalDataHelpers';
import { AllExternalData } from './externalDataTypes';

export const selectPersons = (state: RootState) => {
  return state.externalData.data[pathMap.persons].items;
};

export const selectOus = (state: RootState) => {
  return state.externalData.data[pathMap.ous].items;
};

export const selectExternalContent = (state: RootState) => {
  return state.externalData.data[pathMap.content].items;
};

export const selectStudyProgrammes = (state: RootState) => {
  return state.externalData.data[pathMap.studyProgrammes].items;
};

export const selectStudyProgrammeGroups = (state: RootState) => {
  return state.externalData.data[pathMap.studyProgrammeGroups].items;
};

export const selectNewsletterSendersArray = createTypedSelector(
  [(state) => state.externalData.data[pathMap.newsletterSenders].items],
  (senders) => Object.values(senders)
);

export const selectIsResourceLoaded = (state: RootState, resource: string) => {
  return (
    state.externalData.data[resource].isLoading === false &&
    Object.keys(state.externalData.data[resource].items).length > 0
  );
};

export const selectAllExternalData = createTypedSelector(
  [(state) => state.externalData.data, selectAllReferenceFrameData],
  (data, { content: referenceFrameContent }): AllExternalData => {
    const allData: AllExternalData = { ...referenceFrameContent };
    Object.values(data).forEach((allItemsOfResource) => {
      Object.entries(allItemsOfResource.items).forEach(([key, value]) => {
        allData[key] = value;
      });
    });
    return allData;
  }
);

export const selectExternalContentRelationsMap = createTypedSelector(
  [(state) => state.externalData.data[pathMap.contentRelations].items],
  (relations) => {
    return mapRelationsToRelationsToAndFrom(relations);
  }
);

export const selectPathToRootFromExternalData = createTypedSelector(
  [
    (state) => selectExternalContentRelationsMap(state),
    (state) => selectExternalContent(state),
    (state, href: string) => state.externalData.loadedAncestors.some((t) => t === href),
    (state, href: string) => href,
  ],
  (relationsMap, content, isLoaded, href) => {
    if (!isLoaded) {
      return null;
    }

    return Object.freeze(getPathToRoot(relationsMap, content, href));
  }
);
