import { ContentHref } from '@generalTypes/apiTypes';
import { RootState } from '@generalTypes/rootStateTypes';
import {
  selectPathToRootHrefs,
  selectRelationToParent,
} from '@newStore/documentApi/documentApiSelectors';
import {
  selectContentNode,
  selectContentNodeChildren,
  selectIsNodeProposedToDeleted,
} from '@newStore/documentUI/documentUISelectors';
import { dropItem, setDropBelowItem } from '@newStore/documentUI/documentUIState';
import { ContentNode } from '@newStore/documentUI/documentUITypes';
import { getBuildingBlockType } from '@newStore/documentUI/documentUIHelpers';
import {
  selectBuildingBlocksForNode,
  selectTypeNameSingle,
} from '@newStore/documentUI/nodeTypeConfigSelectors';
import { DropType, HoverPosition, HoverPositionEnum } from '@nodeTypeConfig/configTypes';
import React, { ReactNode, useEffect, useState } from 'react';
import { XYCoord, useDrag, useDrop } from 'react-dnd';
import { createPortal } from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import DropLine from './DropLine';
import DropZone from './DropZone';

import './DragAndDrop.scss';

const selectIsLastChild = (
  state: RootState,
  href: string,
  parentHref: ContentHref | undefined,
  draggingHref: string | null
) => {
  if (!parentHref) {
    return false;
  }
  let childHrefs = selectContentNodeChildren(state, parentHref);
  if (!childHrefs) {
    return false;
  }
  if (draggingHref) {
    childHrefs = childHrefs.filter((z) => z !== draggingHref);
  }
  return Boolean(childHrefs && childHrefs[childHrefs.length - 1] === href);
};

const emptyArray = [] as const;

const DragAndDropRow: React.FC<{
  contentNode: ContentNode;
  parentHref: ContentHref | undefined;
  rowRef: React.MutableRefObject<HTMLDivElement>;
  dragRef: React.MutableRefObject<HTMLDivElement>;
  dropChildZoneRef: React.MutableRefObject<HTMLDivElement>;
  onUnCollapse: () => void;
  onDragChange: (isDragging: boolean) => void;
  children: ReactNode;
  disableDrag: boolean;
  disableDrop: boolean;
}> = ({
  contentNode,
  parentHref,
  rowRef,
  dragRef,
  onDragChange,
  onUnCollapse,
  children,
  disableDrag,
  disableDrop,
  dropChildZoneRef,
}) => {
  const [hoverPosition, setHoverPosition] = useState<HoverPosition>(null);
  const [canDrop, setCanDrop] = useState(false);
  const dispatch = useDispatch();

  const buildingBlocksForChild = useSelector(
    (state: RootState) =>
      (!selectIsNodeProposedToDeleted(state, contentNode.href) &&
        selectBuildingBlocksForNode(state, contentNode.href as ContentHref)) ||
      emptyArray
  );

  const parentNode = useSelector(
    (state: RootState) => parentHref && selectContentNode(state, parentHref)
  );

  const buildingBlocksForSiblings = useSelector(
    (state: RootState) =>
      parentHref !== undefined &&
      !selectIsNodeProposedToDeleted(state, parentHref) &&
      selectBuildingBlocksForNode(state, parentHref as ContentHref)
  );

  const relationToParent = useSelector(
    (state: RootState) => parentHref && selectRelationToParent(state, contentNode.href, parentHref)
  );

  const typeNameSingle = useSelector((state: RootState) =>
    selectTypeNameSingle(state, contentNode.href)
  );

  const parentTypeNameSingle = useSelector(
    (state: RootState) => parentHref && selectTypeNameSingle(state, parentHref)
  );

  const [{ isDragging, draggingHref }, dragHandleRef, preview] = useDrag(
    () => ({
      type: 'contentRow',
      item: {
        node: contentNode,
        buildingBlockType: getBuildingBlockType(contentNode),
        relation: relationToParent,
      },
      end: () => {
        dispatch(setDropBelowItem(null));
      },
      canDrag: () => {
        return !disableDrag;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
        draggingHref: monitor.getItem()?.node?.href || null,
      }),
    }),
    [contentNode, relationToParent]
  );

  if (!disableDrag) {
    dragHandleRef(dragRef);
  }

  const isLastChild = useSelector((state: RootState) =>
    selectIsLastChild(state, contentNode.href, parentHref, draggingHref)
  );

  const dropBelowItemIsNull = useSelector((state: RootState) => {
    const { dropBelowItem } = state.documentUI;
    return dropBelowItem === null;
  });

  /**
   * this selector will show the drop below box (recursively upwards) as long as the item is the last child.
   */
  const showDropBelow = useSelector((state: RootState) => {
    const { dropBelowItem } = state.documentUI;
    if (!dropBelowItem) {
      return false;
    }

    if (contentNode.href === state.documentUI.currentDocument) {
      // is root
      return false;
    }

    // when we hover over an item for some time, the dropBelowItem will be set.
    // we select the path to the root from the dropBelowItem href.
    const pathToRoot = selectPathToRootHrefs(state, dropBelowItem);

    // then we get the index of the first item on the path upwards, that is not a last child.
    const index = pathToRoot.findIndex(
      (href, idx) => !selectIsLastChild(state, href, pathToRoot[idx + 1], draggingHref)
    );
    if (index === -1) {
      return false;
    }

    // we slice the path to root, so we have an array of last items.
    const pathToRootOfLastItems = pathToRoot.slice(0, index + 1);

    // if the current node occurs, we show the drop below box.
    return pathToRootOfLastItems.some((href) => href === contentNode.href);
  });

  // is checked in validation rule where you get a clearer messages that it is not allowed (instead of just not allowing to drop without message)
  const canDropSiblingCheck = (item: DropType) => {
    const buildingBlock =
      buildingBlocksForSiblings &&
      buildingBlocksForSiblings.find((z) => z.type === item.buildingBlockType);
    if (!buildingBlock) {
      return false;
    }

    return !disableDrop;
  };

  const canDropChildCheck = (item: DropType) => {
    const buildingBlock = buildingBlocksForChild.find((z) => z.type === item.buildingBlockType);
    if (!buildingBlock) {
      return false;
    }

    return !disableDrop;
  };

  useEffect(() => {
    onDragChange(isDragging);
  }, [isDragging, onDragChange]);

  const [{ isOverCurrent, dragItemHref }, dropOnRef] = useDrop<
    DropType,
    void,
    {
      isOverCurrent: boolean;
      dragItemHref: string | null;
    }
  >(
    () => ({
      accept: ['contentRow', 'buildingblock'],
      drop: (item) => {
        console.log('dropped', item, hoverPosition, contentNode);
        const dropParentHref =
          hoverPosition === HoverPositionEnum.IN ? contentNode.href : (parentHref as string);
        const siblingHref = hoverPosition === HoverPositionEnum.IN ? null : contentNode.href;
        if (hoverPosition === HoverPositionEnum.IN) {
          onUnCollapse();
        }
        dispatch(
          dropItem({
            droppedItem: item,
            parentHref: dropParentHref,
            siblingHref,
            position: hoverPosition as HoverPositionEnum,
          })
        );
      },
      canDrop: (item) => {
        if (hoverPosition === HoverPositionEnum.IN) {
          return canDropChildCheck(item);
        }
        return canDropSiblingCheck(item);
      },
      collect: (monitor) => {
        const item = monitor.getItem();
        const href = (item && !('newNode' in item) && item.node?.href) || null;
        return {
          isOverCurrent: monitor.isOver({ shallow: true }), // show only this level
          dragItemHref: href,
        };
      },
      hover: (item, monitor) => {
        if (rowRef.current) {
          const hoverBoundingRect = rowRef.current.getBoundingClientRect();
          const hoverHalfY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
          const clientOffset = monitor.getClientOffset();
          const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

          const canDropAbove = canDropSiblingCheck(item);
          const canDropBelow = canDropSiblingCheck(item);
          const canDropIn = canDropChildCheck(item);

          if (canDropAbove && canDropBelow && !canDropIn) {
            if (hoverClientY < hoverHalfY) {
              setHoverPosition(HoverPositionEnum.ABOVE);
              setCanDrop(canDropAbove);
            } else {
              setHoverPosition(HoverPositionEnum.BELOW);
              setCanDrop(canDropBelow);
            }
          } else if (canDropIn && !canDropAbove && !canDropBelow) {
            setHoverPosition(HoverPositionEnum.IN);
            setCanDrop(canDropIn);
          } else {
            // eslint-disable-next-line no-lonely-if
            if (hoverClientY < hoverHalfY) {
              setHoverPosition(HoverPositionEnum.ABOVE);
              setCanDrop(canDropAbove);
            } else {
              setHoverPosition(HoverPositionEnum.IN);
              setCanDrop(canDropIn);
            }
          }
        }
      },
    }),
    [contentNode, hoverPosition]
  );

  const [{ isOverDropzone }, dropBelowRef] = useDrop<
    DropType,
    void,
    {
      isOverDropzone: boolean;
    }
  >(
    () => ({
      accept: ['contentRow', 'buildingblock'],
      drop: (item) => {
        console.log('dropped', item, HoverPositionEnum.BELOW, contentNode);
        const dropParentHref = parentHref as string;
        const siblingHref = contentNode.href;
        dispatch(
          dropItem({
            droppedItem: item,
            parentHref: dropParentHref,
            siblingHref,
            position: HoverPositionEnum.BELOW,
          })
        );
      },
      canDrop: (item) => {
        return canDropSiblingCheck(item);
      },
      collect: (monitor) => {
        return {
          isOverDropzone: monitor.isOver({ shallow: true }), // show only this level
        };
      },
    }),
    [contentNode, hoverPosition]
  );

  const [{ isOverChildDropzone }, dropOnChildRef] = useDrop<
    DropType,
    void,
    {
      isOverChildDropzone: boolean;
    }
  >(
    () => ({
      accept: ['contentRow', 'buildingblock'],
      drop: (item) => {
        console.log('dropped', item, HoverPositionEnum.IN, contentNode);
        onUnCollapse();
        dispatch(
          dropItem({
            droppedItem: item,
            parentHref: contentNode.href,
            siblingHref: null,
            position: hoverPosition as HoverPositionEnum,
          })
        );
      },
      canDrop: (item) => {
        return canDropChildCheck(item);
      },
      collect: (monitor) => {
        return {
          isOverChildDropzone: monitor.isOver({ shallow: true }), // show only this level
        };
      },
    }),
    [contentNode, hoverPosition]
  );

  const hasChildren = useSelector((state: RootState) => {
    const childHrefs = selectContentNodeChildren(state, contentNode.href);

    return Boolean(childHrefs && childHrefs.filter((z) => z !== dragItemHref).length > 0);
  });

  useEffect(() => {
    let cancel: NodeJS.Timeout | null = null;

    if (isOverCurrent && hoverPosition === HoverPositionEnum.IN) {
      cancel = setTimeout(() => {
        onUnCollapse();
      }, 500);
    }

    return () => {
      if (cancel) {
        clearTimeout(cancel);
      }
    };
  }, [onUnCollapse, isOverCurrent, hoverPosition]);

  useEffect(() => {
    let cancel: NodeJS.Timeout | null = null;

    if (
      isOverCurrent &&
      (hoverPosition === HoverPositionEnum.BELOW ||
        (hoverPosition === HoverPositionEnum.IN && !hasChildren))
    ) {
      cancel = setTimeout(() => {
        dispatch(setDropBelowItem({ href: contentNode.href }));
      }, 500);
    } else if (isOverCurrent && hoverPosition === HoverPositionEnum.ABOVE && !dropBelowItemIsNull) {
      dispatch(setDropBelowItem(null)); // Clear setDropBelowItem
    }

    return () => {
      if (cancel) {
        clearTimeout(cancel);
      }
    };
  }, [
    isLastChild,
    isOverCurrent,
    hoverPosition,
    contentNode.href,
    dispatch,
    hasChildren,
    dropBelowItemIsNull,
  ]);

  preview(dropOnRef(rowRef));

  if (disableDrag && disableDrop) {
    return <>{children}</>;
  }

  return (
    <>
      <div className="dropAbove">
        <DropLine
          visible={isOverCurrent && hoverPosition === HoverPositionEnum.ABOVE && canDrop}
        ></DropLine>
      </div>
      {children}
      {dropChildZoneRef?.current &&
        createPortal(
          <div className="dropIn">
            <DropLine
              visible={isOverCurrent && hoverPosition === HoverPositionEnum.IN && canDrop}
            ></DropLine>
            <div ref={dropOnChildRef}>
              <DropZone
                visible={
                  showDropBelow && canDrop && hoverPosition === HoverPositionEnum.IN && !hasChildren
                }
                onHover={isOverChildDropzone}
              >
                Plaats onder {('title' in contentNode && contentNode.title) || typeNameSingle}
              </DropZone>
            </div>
          </div>,
          dropChildZoneRef.current
        )}
      <div className="dropBelow" ref={dropBelowRef}>
        <DropLine
          visible={isOverCurrent && hoverPosition === HoverPositionEnum.BELOW && canDrop}
        ></DropLine>
        <DropZone visible={showDropBelow} onHover={isOverDropzone}>
          Plaats onder{' '}
          {(parentNode && 'title' in parentNode && parentNode.title) || parentTypeNameSingle}
        </DropZone>
      </div>
    </>
  );
};

export default DragAndDropRow;
