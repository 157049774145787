import { BuildingBlockTile } from '@newStore/documentUI/documentUITypes';
import { useDrag } from 'react-dnd';

import './BuildingBlock.scss';
import { useDispatch } from 'react-redux';
import { setDropBelowItem } from '@newStore/documentUI/documentUIState';
import CustomTooltip from '@UI/tooltip/CustomTooltip';

const BuildingBlock: React.FC<BuildingBlockTile> = ({ type, icon, name }) => {
  const dispatch = useDispatch();

  // @ts-expect-error we dont use the isDragging yet
  const [{ isDragging }, dragRef, preview] = useDrag(
    () => ({
      type: 'buildingblock',
      item: { node: { type }, buildingBlockType: type, newNode: true },
      end: (item, monitor) => {
        dispatch(setDropBelowItem(null));
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    }),
    [type]
  );

  return (
    <CustomTooltip name={name}>
      <div
        ref={dragRef}
        key={type}
        className="ribbon-button"
        style={{ backgroundImage: `url(${icon})` }}
      >
        {!icon && name && <div className="ribbon-label">{name}</div>}
      </div>
    </CustomTooltip>
  );
};

export default BuildingBlock;
