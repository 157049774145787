export const config = {
  $$identifier: {
    allowedTags: [],
    allowedAttributes: {}
  },
  title: {
    allowedTags: ['br', 'a', 'sub', 'sup', 'span'],
    allowedAttributes: {
      a: ['href', 'rel', 'root', 'target'],
      span: ['data-href', 'data-rel']
    },
    transformTags: {
      p: 'br',
      i: 'em',
      b: 'strong'
    }
  },
  description: {
    allowedTags: ['b', 'i', 'em', 'strong', 'br', 'ol', 'ul', 'li', 'a', 'table', 'tr', 'td', 'tbody', 'thead', 'th', 'hr', 'sub', 'sup', 'span'],
    allowedAttributes: {
      a: ['href', 'rel', 'root', 'target'],
      table: ['style', 'border'],
      td: ['colspan', 'rowspan', 'style'],
      span: ['data-href', 'data-rel']
    },
    transformTags: {
      p: 'br',
      i: 'em',
      b: 'strong'
    }
  },
  shortdescription: {
    allowedTags: ['b', 'i', 'em', 'strong', 'br', 'ol', 'ul', 'li', 'a', 'table', 'tr', 'td', 'tbody', 'thead', 'th', 'hr', 'sub', 'sup'],
    allowedAttributes: {
      a: ['href', 'rel', 'root', 'target'],
      table: ['style', 'border'],
      td: ['colspan', 'rowspan', 'style'],
    },
    transformTags: {
      p: 'br',
      i: 'em',
      b: 'strong'
    }
  },
  html: {
    allowedTags: ['b', 'i', 'em', 'strong', 'br', 'ol', 'ul', 'li', 'a', 'img', 'table', 'tbody', 'tr', 'td', 'sub', 'sup', 'span'],
    allowedAttributes: {
      a: ['href', 'rel', 'root', 'title', 'identifier', 'target'],
      table: ['style', 'border'],
      td: ['colspan', 'rowspan', 'style'],
      span: ['data-href', 'data-rel']
    },
    transformTags: {
      i: 'em',
      b: 'strong',
      p: 'br'
    }
  },
  clearAll: {
    allowedTags: [],
    allowedAttributes: {}
  }
};
