import { selectZillGoalByHref } from '@newStore/zill/zillSelectors';

class zillIllustrationModal {
  constructor($notification, $translate, $ngRedux, $scope) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  hasError() {
    return false;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close(this.title);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  $onInit() {
    this.change = (text) => {
      // key pressed
      this.title = text;
    };
    if (this.resolve.modalData.subDetail) {
      this.subDetail = this.resolve.modalData.subDetail;
    }
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const goal = selectZillGoalByHref(state, this.resolve.modalData.goalHref);

      return {
        goal,
        loading: goal.isLoading,
      };
    })(this);

    this.title = '';
  }

  $onDestroy() {
    this.unsubscribe();
  }
}


export default {
  template: require('./zillIllustrationModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: zillIllustrationModal
};
