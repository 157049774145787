import { parse, stringify } from 'himalaya';
import uuidv4 from 'uuid/v4';
import { settings } from '../config/settings';
import { sanitizeHTML } from '@store/helpers/documentHelpers';

const SriClientError = require('@kathondvla/sri-client/sri-client-error');

function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = unescape(dataURI.split(',')[1]);
  }

  // separate out the mime component
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  var i;
  for (i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export const readFileInputEventAsArrayBuffer = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    reader.onload = (loadEvent) => {
      var arrayBuffer = loadEvent.target.result;
      resolve({
        name: file.name,
        arrayBuffer: arrayBuffer
      });
    };

    reader.onprogress = (data) => {
      if (data.lengthComputable) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        console.log('PROGRESS: ' + progress);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

export const createNewsLetter = async (batch, newsLetterHref, items) => {
  const attachmentsToUpload = [];
  const newBatch = structuredClone(batch);
  copyTemplateMeta(
    newBatch,
    items.find((i) => i.type === 'STRUCTURED_DOCUMENT')
  );
  addNewsLetterChildren(
    newBatch,
    attachmentsToUpload,
    newsLetterHref,
    items.find((i) => i.type === 'STRUCTURED_DOCUMENT').$$meta.permalink,
    items
  );

  return { batch: newBatch, attachmentsToUpload };
};

const copyTemplateMeta = (batch, templateRoot) => {
  let body = batch[0].body;

  body.coverage = templateRoot.coverage;
  body.mainstructuresOuTypeCombinations = templateRoot.mainstructuresOuTypeCombinations;
  body.mainstructures = templateRoot.mainstructures;
  body.outypes = templateRoot.outypes;
  body.positions = templateRoot.positions;
  body.newsletterType = templateRoot.newsletterType;
  body.tableOfContents = templateRoot.tableOfContents;
  body.newsletterSender = templateRoot.newsletterSender;
};

const addNewsLetterChildren = (batch, attachmentsToUpload, newsLetterHref, templateHref, items) => {
  const children = items.filter(t => t.$$relationsFrom.find(r => r.$$expanded.to.href === templateHref));
  children.forEach(i => {
    const contentKey = uuidv4();
    batch.push({
      verb: 'PUT',
      href: '/content/' + contentKey,
      body: {
        ...i,
        key: contentKey,
        attachments: i.attachments.filter(a => a.type === 'CONTENT').map(a => {
          return {
            ...a,
            key: uuidv4(),
            text: i.$$html
          };
        })
      }
    });

    i.attachments.filter(a => a.type === 'ILLUSTRATION').forEach(a => {
      attachmentsToUpload.push({
        resourceKey: contentKey,
        key: uuidv4(),
        name: a.name,
        $$url: settings.apisAndUrls.contentApi + a.href,
        type: a.type
      });
    });

    i.$$relationsFrom.forEach(r => {
      const relationKey = uuidv4();
      batch.push({
        verb: 'PUT',
        href: '/content/relations/' + relationKey,
        body: {
          key: relationKey,
          from: { href: '/content/' + contentKey },
          to: { href: r.$$expanded.relationtype === 'IS_PART_OF' ? newsLetterHref : r.$$expanded.to.href },
          relationtype: r.$$expanded.relationtype,
          readorder: r.$$expanded.readorder
        }
      });
    });

    addNewsLetterChildren(batch, attachmentsToUpload, '/content/' + contentKey, i.$$meta.permalink, items);
  });
};

const jsonToHtml = (json) => sanitizeHTML(stringify(json), 'html');

export const documentAsDocx = async (docx, batch) => {
  const mammoth = require('mammoth'); //eslint-disable-line

  const mammothResult = await mammoth.convertToHtml({ arrayBuffer: docx }, {
    styleMap: [
      'p[style-name=\'Title\'] => title',
      'p[style-name=\'Subtitle\'] => description',
      'p[style-name=\'heading 5\'] => h5',
      'p[style-name=\'heading 6\'] => h6'
    ]
  });

  let rootDocument = { ...batch[0].body };

  // The generated HTML
  const html = mammothResult.value;
  // Parse HTML to json
  let jsonFromHtml = parse(html);

  // Look if there is a title
  const indexOfTitle = jsonFromHtml.findIndex(o => o.tagName === 'title');
  if (indexOfTitle > -1) {
    rootDocument.title = jsonToHtml(jsonFromHtml[indexOfTitle].children);
    jsonFromHtml = jsonFromHtml.splice(indexOfTitle + 1);
  }

  // Look if there is a description
  const indexOfDescription = jsonFromHtml.findIndex(o => o.tagName === 'description');
  if (indexOfDescription > -1) {
    rootDocument.description = jsonToHtml(jsonFromHtml[indexOfDescription].children);
    jsonFromHtml = jsonFromHtml.splice(indexOfDescription + 1);
  }

  function handleNextItem(nextItems, array) {
    if (nextItems.length > 0) {
      const nextItem = nextItems[0];
      const itemsUnder = nextItems.slice(1, nextItems.length);
      if (/^h[0-9]*$/.test(nextItem.tagName)) {
        let nextWithSameTagIndex = itemsUnder.findIndex(e => e.tagName === nextItem.tagName);
        if (nextWithSameTagIndex === -1) {
          nextWithSameTagIndex = itemsUnder.length;
        }
        const items = [];
        handleNextItem(itemsUnder.splice(0, nextWithSameTagIndex), items);
        array.push({
          key: uuidv4(),
          type: 'SECTION',
          title: jsonToHtml(nextItem.children).replace(/<[^>]+>/g, ''),
          items: items
        });
      } else if (nextItem.tagName === 'p') {
        // check if has image
        const imgIndex = nextItem.children.findIndex(e => e.tagName === 'img');
        if (imgIndex > -1) {
          const image = nextItem.children.splice(imgIndex, 1)[0];
          const imageSrc = image.attributes.filter(o => o.key === 'src')[0].value;
          if (nextItem.children.length === 1) {
            // paragraph has illustration
            array.push({
              key: uuidv4(),
              type: 'PARAGRAPH',
              text: jsonToHtml(nextItem.children),
              src: imageSrc
            });
          } else {
            // this is a standalone image!
            array.push({
              key: uuidv4(),
              type: 'IMAGE',
              src: imageSrc
            });
          }
        } else {
          // no image in paragraph
          const text = jsonToHtml(nextItem.children);
          if (text) {
            array.push({
              key: uuidv4(),
              type: 'PARAGRAPH',
              text,
            });
          }
        }
      } else if (nextItem.tagName === 'ol' || nextItem.tagName === 'ul') {
        const prev = array.slice(-1)[0];
        if (prev && prev.type === 'PARAGRAPH') {
          // add to previous, if previous is a paragraph
          prev.text = prev.text
            + '<' + nextItem.tagName + '>'
            + jsonToHtml(nextItem.children)
            + '</' + nextItem.tagName + '>';
        } else {
          // create new paragraph if previous is not a paragraph
          array.push({
            key: uuidv4(),
            type: 'PARAGRAPH',
            text: '<' + nextItem.tagName + '>' + jsonToHtml(nextItem.children) + '</' + nextItem.tagName + '>'
          });
        }
      }
      if (itemsUnder.length > 0) {
        handleNextItem(itemsUnder, array);
      }
    }
  }

  // const batch = contentApi.createBatch();
  function flattenTree(tree) {
    const attachments = [];

    function addItems(items, parent) {
      items.forEach((item, i) => {
        const contentItem = { ...item, importance: 'MEDIUM', attachments: [] };
        if (item.text || item.src) { // ILLUSTRATION needs to have a CONTENT node too in content-api
          contentItem.attachments.push({
            key: item.key,
            text: item.text || '',
            type: 'CONTENT',
            contentType: 'text/html',
            name: 'content.html'
          });
          delete contentItem.text;
        }
        // batch.put('/content/' + item.key, contentItem);
        batch.push({
          verb: 'PUT',
          href: '/content/' + item.key,
          body: contentItem
        });

        const relationKey = uuidv4();
        let relation = {
          key: relationKey,
          from: {
            href: '/content/' + item.key
          },
          to: {
            href: '/content/' + parent.key
          },
          relationtype: 'IS_PART_OF',
          readorder: i + 1
        };

        // batch.put('/content/relations/' + relationKey, relation);
        batch.push({
          verb: 'PUT',
          href: '/content/relations/' + relationKey,
          body: relation
        });

        if (item.src) {
          let file = dataURItoBlob(item.src);
          const extension = require('mime').getExtension(file.type);
          file.name = 'imported_illustration.' + extension;

          attachments.push({
            resourceKey: item.key,
            key: uuidv4(),
            file: file,
            type: 'ILLUSTRATION'
          });
          delete item.src;
        }
        if (item.items) {
          addItems(item.items, item);
        }
      });
    }

    if (tree) {
      // if (tree.$$meta) {
      //   batch.put('/content/' + tree.key, Object.assign({}, tree, { importance: 'MEDIUM', attachments: [] }));
      // }
      if (tree.items) {
        addItems(tree.items, tree);
      }
    }
    return attachments;
  }

  const array = [];
  handleNextItem(jsonFromHtml, array);
  rootDocument.items = array;
  const attachments = flattenTree(rootDocument);

  // try {
  //   await batch.send('/content/batch');
  // } catch (error) {
  //   if (error instanceof SriClientError) {
  //     console.error('[import.js]: ERROR uploading document');
  //     console.error(error.stack);
  //   } else {
  //     console.error(error);
  //   }
  // }

  // await Promise.all(attachments.filter(o => o.type === 'ILLUSTRATION').map(o => sendAttachment(o.key, o.image)));

  return { batch, attachmentsToUpload: attachments.filter(o => o.type === 'ILLUSTRATION') };
};

export default ['$q', 'settings', 'BackendManager', 'contentApi',  //eslint-disable-line
  function ($q, settings, BackendManager, contentApi) { //eslint-disable-line

    const SriClientError = require('@kathondvla/sri-client/sri-client-error');

    var service = {};

    function dataURItoBlob(dataURI) {
      // convert base64/URLEncoded data component to raw binary data held in a string
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0) {
        byteString = atob(dataURI.split(',')[1]);
      } else {
        byteString = unescape(dataURI.split(',')[1]);
      }

      // separate out the mime component
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      var i;
      for (i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], { type: mimeString });
    }

    function sendAttachment(key, blob) {
      var defer = $q.defer();

      // the extension depends on the blob.type
      const extension = require('mime').getExtension(blob.type);

      blob.name = 'imported_illustration.' + extension;

      BackendManager.putAttachment({ $$meta: { permalink: settings.resourcesNames.content + '/' + key } },
        { file: blob, type: 'ILLUSTRATION', key: uuidv4() })
        .then(function () {
          defer.resolve(true);
        });

      return defer.promise;
    }

    service.readFileInputEventAsArrayBuffer = function (event, callback) {
      var file = event.target.files[0];

      var reader = new FileReader();

      reader.onload = function (loadEvent) {
        var arrayBuffer = loadEvent.target.result;
        callback(arrayBuffer);
      };

      reader.onprogress = function (data) {
        if (data.lengthComputable) {
          var progress = parseInt(data.loaded / data.total * 100, 10);
          console.log('PROGRESS: ' + progress);
        }
      };

      reader.readAsArrayBuffer(file);
    };

    /**
     * Receive a docs input and insert it in content-api as a VISION_TEXT
     */
    service.documentAsDocx = async function (docx, rootDocument) {
      var mammoth = require('mammoth'); //eslint-disable-line
      var defer = $q.defer();

      const mammothResult = await mammoth.convertToHtml({ arrayBuffer: docx }, {
        styleMap: [
          'p[style-name=\'Title\'] => title',
          'p[style-name=\'Subtitle\'] => description',
          'p[style-name=\'heading 5\'] => h5',
          'p[style-name=\'heading 6\'] => h6'
        ]
      });

      // The generated HTML
      const html = mammothResult.value;

      // Parse HTML to json
      let jsonFromHtml = parse(html);

      // Look if there is a title
      const indexOfTitle = jsonFromHtml.findIndex(o => o.tagName === 'title');
      if (indexOfTitle > -1) {
        rootDocument.title = jsonToHtml(jsonFromHtml[indexOfTitle].children);
        jsonFromHtml = jsonFromHtml.splice(indexOfTitle + 1);
      }

      // Look if there is a description
      const indexOfDescription = jsonFromHtml.findIndex(o => o.tagName === 'description');
      if (indexOfDescription > -1) {
        rootDocument.description = jsonToHtml(jsonFromHtml[indexOfDescription].children);
        jsonFromHtml = jsonFromHtml.splice(indexOfDescription + 1);
      }

      function handleNextItem(nextItems, array) {
        if (nextItems.length > 0) {
          const nextItem = nextItems[0];
          const itemsUnder = nextItems.slice(1, nextItems.length);
          if (/^h[0-9]*$/.test(nextItem.tagName)) {
            let nextWithSameTagIndex = itemsUnder.findIndex(e => e.tagName === nextItem.tagName);
            if (nextWithSameTagIndex === -1) {
              nextWithSameTagIndex = itemsUnder.length;
            }
            const items = [];
            handleNextItem(itemsUnder.splice(0, nextWithSameTagIndex), items);
            array.push({
              key: uuidv4(),
              type: 'SECTION',
              title: jsonToHtml(nextItem.children),
              items: items
            });
          } else if (nextItem.tagName === 'p') {
            // check if has image
            const imgIndex = nextItem.children.findIndex(e => e.tagName === 'img');
            if (imgIndex > -1) {
              const image = nextItem.children.splice(imgIndex, 1)[0];
              const imageSrc = image.attributes.filter(o => o.key === 'src')[0].value;
              if (nextItem.children.length === 1) {
                // paragraph has illustration
                array.push({
                  key: uuidv4(),
                  type: 'PARAGRAPH',
                  text: jsonToHtml(nextItem.children),
                  src: imageSrc
                });
              } else {
                // this is a standalone image!
                array.push({
                  key: uuidv4(),
                  type: 'IMAGE',
                  src: imageSrc
                });
              }
            } else {
              // no image in paragraph
              array.push({
                key: uuidv4(),
                type: 'PARAGRAPH',
                text: jsonToHtml(nextItem.children)
              });
            }
          } else if (nextItem.tagName === 'ol' || nextItem.tagName === 'ul') {
            const prev = array.slice(-1)[0];
            if (prev && prev.type === 'PARAGRAPH') {
              // add to previous, if previous is a paragraph
              prev.text = prev.text
                + '<' + nextItem.tagName + '>'
                + jsonToHtml(nextItem.children)
                + '</' + nextItem.tagName + '>';
            } else {
              // create new paragraph if previous is not a paragraph
              array.push({
                key: uuidv4(),
                type: 'PARAGRAPH',
                text: '<' + nextItem.tagName + '>' + jsonToHtml(nextItem.children) + '</' + nextItem.tagName + '>'
              });
            }
          }
          if (itemsUnder.length > 0) {
            handleNextItem(itemsUnder, array);
          }
        }
      }

      const batch = contentApi.createBatch();
      function flattenTree(tree) {
        const attachments = [];

        function addItems(items, parent) {
          items.forEach((item, i) => {
            const contentItem = { ...item, importance: 'MEDIUM', attachments: [] };
            if (item.text) {
              contentItem.attachments.push({
                key: item.key,
                text: item.text,
                type: 'CONTENT',
                contentType: 'text/html',
                name: 'content.html'
              });
              delete contentItem.text;
            }
            batch.put('/content/' + item.key, contentItem);
            const relationKey = uuidv4();
            batch.put('/content/relations/' + relationKey, {
              key: relationKey,
              from: {
                href: '/content/' + item.key
              },
              to: {
                href: '/content/' + parent.key
              },
              relationtype: 'IS_PART_OF',
              readorder: i + 1
            });
            if (item.src) {
              attachments.push({
                key: item.key,
                image: dataURItoBlob(item.src),
                type: 'ILLUSTRATION'
              });
              delete item.src;
            }
            if (item.items) {
              addItems(item.items, item);
            }
          });
        }

        if (tree) {
          if (tree.$$meta) {
            batch.put('/content/' + tree.key, { ...tree, importance: 'MEDIUM', attachments: [] });
          }
          if (tree.items) {
            addItems(tree.items, tree);
          }
        }
        return attachments;
      }

      const array = [];
      handleNextItem(jsonFromHtml, array);
      rootDocument.items = array;
      const attachements = flattenTree(rootDocument);

      try {
        await batch.send('/content/batch');
      } catch (error) {
        if (error instanceof SriClientError) {
          console.error('[import.js]: ERROR uploading document');
          console.error(error.stack);
        } else {
          console.error(error);
        }
      }

      await Promise.all(attachements.filter(o => o.type === 'ILLUSTRATION').map(o => sendAttachment(o.key, o.image)));

      defer.resolve(rootDocument);
      return rootDocument;
    };

    return service;
  }];
