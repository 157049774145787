require('./browseFileModal.scss');

class BrowseFileModal {
  constructor($scope) {
    'ngInject';

    this.$scope = $scope;
  }

  $onInit() {
    this.modalHeaderTitle = this.resolve.modalHeaderTitle;
    this.browseLabel = this.resolve.browseLabel;
    angular.element(document.querySelector('#uploadFile')).on('change', this.handleUploadFileSelect.bind(this));
  }

  clickBrowseForUpload() {
    angular.element(document.querySelector('#uploadFile')).click();
  }

  async handleUploadFileSelect(event) {
    this.file = event.target.files[0];
    this.hasFilenameChanged =
      this.file.name &&
      this.resolve.content &&
      this.file.name !== this.resolve.content.name &&
      this.file.name !== this.resolve.content.originalName;
    this.$scope.$apply();
  }

  submit() {
    this.close({ $value: this.file });
  }

  cancel() {
    this.dismiss();
  }
}

export const browseFileModalComponent = {
  template: require('./browseFileModal.html'),
  controllerAs: 'ctrl',
  controller: BrowseFileModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&'
  }
};
