/* eslint-disable max-len */
import * as ACTION_TYPES from '../constants/actionTypes';
import uuidv4 from 'uuid/v4';

export const openWindowAction = (window, key) => ({
  type: ACTION_TYPES.OPEN_WINDOW,
  payload: {
    window,
    key
  }
});

export const clearRedirect = () => ({
  type: ACTION_TYPES.DOCUMENT_CLEAR_REDIRECT
});

export const fillRelationsWithExpandedResourcesAction = () => ({
  type: ACTION_TYPES.FILL_RELATIONS_WITH_EXPANDED_RESOURCES
});

export const clearSelectionsAction = () => ({
  type: ACTION_TYPES.CLEAR_SELECTIONS
});

export const addNodeToParentNodeAction = (parentKey, position, type, initialNodeData) => ({
  type: ACTION_TYPES.ADD_NODE_TO_PARENT_NODE,
  payload: {
    newKey: uuidv4(),
    newRelationKey: uuidv4(),
    newAttachmentKey: uuidv4(),
    parentKey: parentKey,
    position: position,
    type: type,
    initialNodeData: initialNodeData
  }
});

export const addNodeAction = ($$type, newNode, updateTree = true) => ({
  type: ACTION_TYPES.ADD_NODE,
  payload: {
    $$type: $$type,
    node: {
      key: uuidv4(),
      attachmentKey: uuidv4(),
      ...newNode
    },
    updateTree
  }
});

export const addRelationAction = (newRelation, updateTree = true, markDirtyNodes = true, goalRelationsParams = null) => ({
  type: ACTION_TYPES.ADD_RELATION,
  payload: {
    key: uuidv4(),
    ...newRelation,
    updateTree,
    markDirtyNodes,
    goalRelationsParams
  }
});

export const addExternalRelationAction = (parentKey, position, externalResource) => ({
  type: ACTION_TYPES.ADD_EXTERNAL_RELATION,
  payload: {
    key: uuidv4(),
    parentKey,
    position,
    externalResource
  }
});

export const saveDocumentAction = () => ({
  type: ACTION_TYPES.SAVE_DOCUMENT,
});

export const loadDocumentAction = (key, editKey) => ({
  type: ACTION_TYPES.INIT_DOCUMENT,
  payload: {
    key,
    editKey
  }
});

export const updateDocumentViewModelAction = (forceHashUpdateToAll = false) => ({
  type: ACTION_TYPES.UPDATE_DOCUMENT_VIEW_MODEL,
  payload: {
    forceHashUpdateToAll
  }
});

export const loadReferencesToDocumentAction = () => ({
  type: ACTION_TYPES.LOAD_REFERENCES_TO_DOCUMENT
});


export const updateAsideViewModelAction = (editKey) => ({
  type: ACTION_TYPES.UPDATE_ASIDE_VIEW_MODEL,
  payload: { editKey }
});

export const setDocumentNodesAction = (resources) => ({
  type: ACTION_TYPES.SET_DOCUMENT_NODES,
  payload: resources
});

export const setDocumentProposalsAction = (resources) => ({
  type: ACTION_TYPES.SET_DOCUMENT_PROPOSALS,
  payload: resources
});


export const setExpandedProposalsCreatorsAction = (creators) => ({
  type: ACTION_TYPES.SET_EXPANDED_PROPOSALS_CREATORS,
  payload: creators
});

export const setProposalsExternalContentAction = (creators) => ({
  type: ACTION_TYPES.SET_PROPOSALS_EXTERNAL_CONTENT,
  payload: creators
});

export const setExpandedAsideComponentsValuesAction = (results) => ({
  type: ACTION_TYPES.SET_EXPANDED_ASIDE_COMPONENTS_VALUES,
  payload: results
});

export const expandResourcesAction = () => ({
  type: ACTION_TYPES.EXPAND_RESOURCES
});

export const setExpandedResourcesValuesAction = (results) => ({
  type: ACTION_TYPES.SET_EXPANDED_RESOURCES_VALUES,
  payload: results
});

export const getTreeAsLeafForResourcesAction = () => ({
  type: ACTION_TYPES.GET_TREE_AS_LEAF_FOR_RESOURCES
});

export const setTreeAsLeafForResourcesAction = (results) => ({
  type: ACTION_TYPES.SET_TREE_AS_LEAF_FOR_RESOURCES,
  payload: results
});

export const documentLoadingFailedAction = (error) => ({
  type: ACTION_TYPES.FAILED_LOADING_DOCUMENT,
  payload: error
});

export const updateDocumentTreeAction = (forceHashUpdateToAll, updateViewModels = true) => ({
  type: ACTION_TYPES.UPDATE_DOCUMENT_TREE,
  payload: {
    forceHashUpdateToAll,
    updateViewModels
  }
});

export const updateApiPendingAndWithChangesAction = () => ({
  type: ACTION_TYPES.UPDATE_API_PENDING_AND_WITH_CHANGES
});

export const removeRelationAction = (key, updateTree = true, addRelatedTo = true) => ({
  type: ACTION_TYPES.REMOVE_RELATION,
  payload: {
    key: key,
    updateTree: updateTree,
    addRelatedTo
  }
});

export const removeNodeAction = (key, updateTree = true, parentKey) => ({
  type: ACTION_TYPES.REMOVE_NODE,
  payload: {
    key: key,
    updateTree: updateTree,
    parentKey
  }
});

export const dirtyNodeAction = (key, updateTree = true) => ({
  type: ACTION_TYPES.DIRTY_NODE,
  payload: {
    key: key,
    updateTree: updateTree
  }
});

export const patchRelationAction = (key, patch, goalRelationsParams = null) => ({
  type: ACTION_TYPES.PATCH_RELATION,
  payload: {
    key,
    patch,
    goalRelationsParams
  }
});


export const toggleSelectionAction = (relationKey, updateTree = true, dirtyNode) => ({
  type: ACTION_TYPES.TOGGLE_SELECTION,
  payload: {
    relationKey,
    updateTree,
    dirtyNode
  }
});

export const setSelectionAction = (relationKey, isSelected) => ({
  type: ACTION_TYPES.SET_SELECTION,
  payload: {
    relationKey,
    isSelected
  }
});

export const validateAndRemoveSelectionsAction = () => ({
  type: ACTION_TYPES.VALIDATE_AND_REMOVE_SELECTIONS
});

export const undoAllAction = () => ({
  type: ACTION_TYPES.UNDO_ALL
});

export const patchNodeAction = (key, patch, updateTree = true, parentKey, sanitizeInput = true) => ({
  type: ACTION_TYPES.PATCH_NODE,
  payload: {
    key,
    patch,
    updateTree,
    parentKey,
    sanitizeInput
  }
});

export const setLinkedContentTypeAction = (key, referenceKey, type) => ({
  type: ACTION_TYPES.SET_LINKED_CONTENT_TYPE,
  payload: {
    key,
    referenceKey,
    type
  }
});

export const setLinkedContentCallToActionAction = (parentKey, referenceKey, label, referencedResourceHref) => ({
  type: ACTION_TYPES.SET_LINKED_CALL_TO_ACTION,
  payload: {
    parentKey,
    referenceKey,
    label,
    referencedResourceHref
  }
});

export const addEditLinkReferenceNodeAction = (parentKey, referenceKey, label, referencedResourceHref) => ({
  type: ACTION_TYPES.ADD_EDIT_LINK_REFERENCE_NODE,
  payload: {
    parentKey,
    referenceKey,
    label,
    referencedResourceHref
  }
});

export const addLinkReferenceNodeAction = (parentKey, label, referencedResourceHref, isUnderGroup) => ({
  type: ACTION_TYPES.ADD_LINK_REFERENCE_NODE,
  payload: {
    parentKey,
    label,
    referencedResourceHref,
    isUnderGroup
  }
});

export const editLinkReferenceNodeAction = (parentKey, referenceKey, label, referencedResourceHref) => ({
  type: ACTION_TYPES.EDIT_LINK_REFERENCE_NODE,
  payload: {
    parentKey,
    referenceKey,
    label,
    referencedResourceHref
  }
});

export const documentSavedAction = () => ({
  type: ACTION_TYPES.DOCUMENT_SAVED
});

export const documentSaveFailedAction = (errors) => ({
  type: ACTION_TYPES.DOCUMENT_SAVE_FAILED,
  errors: errors
});

export const setPrivateStateAction = (privateState) => ({
  type: ACTION_TYPES.SET_PRIVATE_STATE,
  payload: privateState
});

export const updatePrivateStateAction = () => ({
  type: ACTION_TYPES.UPDATE_PRIVATE_STATE
});

export const setCollapesedNodesAction = (nodes) => ({
  type: ACTION_TYPES.SET_COLLAPSED_NODES,
  payload: nodes
});

export const toggleCollapseAction = (key, value = undefined, updateTree = true) => ({
  type: ACTION_TYPES.TOGGLE_COLLAPSE,
  payload: { key, value, updateTree }
});

export const hoverOnCollapseAction = (key) => ({
  type: ACTION_TYPES.HOVER_ON_COLLAPSE,
  payload: key
});

export const clearHoverOnCollapseAction = () => ({
  type: ACTION_TYPES.CLEAR_HOVER_ON_COLLAPSE
});

export const moveSelectionsToParentNodeAction = (parentKey, position, relationKey) => ({
  type: ACTION_TYPES.MOVE_SELECTIONS_TO_PARENT_NODE,
  payload: {
    parentKey: parentKey,
    position: position,
    relationKey
  }
});

export const moveAttachmentPositionAction = (relationKey, attachmentGroupKey, containingResourceKey, position) => ({
  type: ACTION_TYPES.MOVE_ATTACHMENT_POSITION,
  payload: {
    relationKey,
    attachmentGroupKey,
    containingResourceKey,
    position
  }
});

export const loadDocumentAuthorsAction = (key, hrefs) => ({
  type: ACTION_TYPES.LOAD_DOCUMENT_AUTHORS,
  payload: { key, hrefs }
});

export const setDocumentAuthorsAction = (results) => ({
  type: ACTION_TYPES.SET_DOCUMENT_AUTHORS,
  payload: results
});

export const loadNamedSetsAction = (tag) => ({
  type: ACTION_TYPES.LOAD_NAMED_SETS,
  payload: {
    tag
  }
});

export const updateNamedSetsAction = (property, namedSets) => ({
  type: ACTION_TYPES.UPDATE_NAMED_SETS,
  payload: {
    property,
    namedSets
  }
});

export const selectNamedSetsAction = (property, selected) => ({
  type: ACTION_TYPES.SELECT_NAMED_SETS,
  payload: {
    property,
    selected
  }
});

export const resetNamedSetsAction = (property, key) => ({
  type: ACTION_TYPES.RESET_NAMED_SETS,
  payload: {
    property,
    key
  }
});

export const loadSubjectsAction = (rootKey) => ({
  type: ACTION_TYPES.LOAD_SUBJECTS,
  payload: rootKey
});

export const setSubjectsAction = (results) => ({
  type: ACTION_TYPES.SET_SUBJECTS,
  payload: results
});

// deprecated
export const addImageAttachmentFileAction = (key, attachment, file) => ({
  type: ACTION_TYPES.ADD_IMAGE_ATTACHMENT_FILE,
  payload: {
    key: key,
    attachment: {
      ...attachment,
      key: uuidv4(),
      href: '/content/' + key + '/attachments/' + attachment.name,
      file: file
    }
  }
});

export const updateWebsiteConfigurationAction = (configuration) => ({
  type: ACTION_TYPES.UPDATE_WEBSITE_CONFIGURATION,
  payload: configuration
});

export const removeWebsiteConfigurationAction = (documentKey, configurationKey) => ({
  type: ACTION_TYPES.REMOVE_WEBSITE_CONFIGURATION,
  payload: {
    documentKey,
    configurationKey
  }
});

export const initWebsiteConfigurationAction = (key, clearFacets) => ({
  type: ACTION_TYPES.INIT_WEBSITE_CONFIGURATION,
  payload: {
    key,
    clearFacets
  }
});

export const setWebsiteConfigurationAction = (configuration) => ({
  type: ACTION_TYPES.SET_WEBSITE_CONFIGURATION,
  payload: configuration
});

export const updateFacetReferenceFramesAction = (documentKey, referenceFrameData) => ({
  type: ACTION_TYPES.UPDATE_FACET_REFERENCE_FRAMES,
  payload: {
    key: documentKey,
    referenceFrameData
  }
});

export const initWholeDocumentWebsiteThemeReferenceFramesAction = () => ({
  type: ACTION_TYPES.INIT_WHOLE_DOCUMENT_WEBSITE_THEME_REFERENCE_FRAMES
});

export const initWebsiteThemeReferenceFramesAction = (documentKey) => ({
  type: ACTION_TYPES.INIT_WEBSITE_THEME_REFERENCE_FRAMES,
  payload: documentKey
});

export const setWebsiteThemeReferenceFramesAction = (result) => ({
  type: ACTION_TYPES.SET_WEBSITE_THEME_REFERENCE_FRAMES,
  payload: result
});

export const loadReferenceFrameExternalOptionsAction = (type, filterUrls, label) => ({
  type: ACTION_TYPES.LOAD_REFERENCE_FRAME_EXTERNAL_OPTIONS,
  payload: {
    type,
    filterUrls,
    label
  }
});

export const setReferenceFrameExternalOptionsAction = (result) => ({
  type: ACTION_TYPES.SET_REFERENCE_FRAME_EXTERNAL_OPTIONS,
  payload: result
});

export const initFieldChoicesAction = (documentKey, field) => ({
  type: ACTION_TYPES.INIT_FIELD_CHOICES,
  payload: {
    key: documentKey,
    field
  }
});

export const setFieldChoicesAction = (data) => ({
  type: ACTION_TYPES.SET_FIELD_CHOICES,
  payload: data
});

export const initLlinkidThemeReferencesAction = (field) => ({
  type: ACTION_TYPES.INIT_LLINKID_THEME_REFERENCES,
  payload: field
});

export const setLlinkidThemeReferencesAction = (result) => ({
  type: ACTION_TYPES.SET_LLINKID_THEME_REFERENCES,
  payload: result
});

export const expandLlinkidGoalRelationsAction = (key, relationTypes, originPart) => ({
  type: ACTION_TYPES.EXPAND_LLINKID_GOAL_RELATIONS,
  payload: {
    key,
    relationTypes,
    originPart
  }
});

export const setExpandedLlinkidGoalRelationsToAction = (result) => ({
  type: ACTION_TYPES.SET_EXPANDED_LLINKID_GOAL_RELATIONS,
  payload: result
});

export const getAllLlinkidCurriculumsAction = () => ({
  type: ACTION_TYPES.GET_ALL_LLINKID_CURRICULUMS
});

export const setAllLlinkidCurriculumsAction = (results) => ({
  type: ACTION_TYPES.SET_ALL_LLINKID_CURRICULUMS,
  payload: results
});

export const loadLlinkidCurriculumPreviousVersionItemsAction = (curriculumKey, type) => ({
  type: ACTION_TYPES.LOAD_LLINKID_CURRICULUM_PREVIOUS_VERSION_ITEMS,
  payload: { key: curriculumKey, type }
});

export const setLlinkidCurriculumPreviousVersionItemsAction = (result) => ({
  type: ACTION_TYPES.SET_LLINKID_CURRICULUM_PREVIOUS_VERSION_ITEMS,
  payload: result
});

export const loadLlinkidCurriculumGoalsAction = (curriculumKey) => ({
  type: ACTION_TYPES.LOAD_LLINKID_CURRICULUM_GOALS,
  payload: curriculumKey
});

export const setLlinkidCurriculumGoalsAction = (result) => ({
  type: ACTION_TYPES.SET_LLINKID_CURRICULUM_GOALS,
  payload: result
});

export const fillLlinkidCurriculumGoalsCompleteIdentifierAction = (result) => ({
  type: ACTION_TYPES.FILL_LLINKID_CURRICULUM_GOALS_COMPLETE_IDENTIFIER,
  payload: result
});

export const loadLlinkidOdetGoalsAction = () => ({
  type: ACTION_TYPES.LOAD_LLINKID_ODET_GOALS
});

export const setLlinkidOdetGoalsAction = (result) => ({
  type: ACTION_TYPES.SET_LLINKID_ODET_GOALS,
  payload: result
});

export const fillLlinkidOdetGoalsCompleteIdentifierAction = (result) => ({
  type: ACTION_TYPES.FILL_LLINKID_ODET_GOALS_COMPLETE_IDENTIFIER,
  payload: result
});

export const loadEducationalActivityTypesAction = () => ({
  type: ACTION_TYPES.GET_EDUCATIONAL_ACTIVITY_TYPES
});

export const setEducationalActivityTypesAction = (results) => ({
  type: ACTION_TYPES.SET_EDUCATIONAL_ACTIVITY_TYPES,
  payload: results
});

export const patchNodeAttachmentAction = (documentKey, attachmentKey, patch, forcePatchNode) => ({
  type: ACTION_TYPES.PATCH_NODE_ATTACHMENT,
  payload: {
    key: documentKey,
    attachmentKey: attachmentKey || uuidv4(),
    patch: patch,
    forcePatchNode
  }
});

// @deprecated
export const removeNodeAttachmentAction = (documentKey, attachmentKey) => ({
  type: ACTION_TYPES.REMOVE_NODE_ATTACHMENT,
  payload: {
    key: documentKey,
    attachmentKey
  }
});

export const expandRelationsAction = (
  key,
  relationsToExpand = 'from',
  relations,
  fetchTreeAsLeaf = false
) => ({
  type: ACTION_TYPES.EXPAND_RELATIONS,
  payload: {
    key,
    relationsToExpand,
    relations,
    fetchTreeAsLeaf
  }
});

export const setExpandedRelationsAction = (result) => ({
  type: ACTION_TYPES.SET_EXPANDED_RELATIONS,
  payload: result
});

export const expandRelationsToAction = (key, relations, fetchTreeAsLeaf) => {
  return expandRelationsAction(key, 'to', relations, fetchTreeAsLeaf);
};

export const expandRelationsFromAction = (key, relations, fetchTreeAsLeaf) => {
  return expandRelationsAction(key, 'from', relations, fetchTreeAsLeaf);
};

export const setSelectedZillCurriculumAsFrame = (relationToCreate) => ({
  type: ACTION_TYPES.SET_SELECTED_ZILL_CURRICULUM_AS_FRAME,
  payload: { relationToCreate }
});

export const editZillCurriculumAsFrame = (relationToEditKey, patchToApply) => ({
  type: ACTION_TYPES.EDIT_ZILL_CURRICULUM_AS_FRAME,
  payload: {
    relationToEditKey,
    patchToApply
  }
});

export const setZillOdetCurriculumAndChildrenAction = (results) => ({
  type: ACTION_TYPES.SET_ZILL_ODET_CURRICULUM,
  payload: results
});

export const loadPracticalExampleZillIllustrationsAction = (key) => ({
  type: ACTION_TYPES.LOAD_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS,
  payload: key
});

export const setPracticalExampleZillIllustrationsAction = (relations) => ({
  type: ACTION_TYPES.SET_PRACTICAL_EXAMPLE_ZILL_ILLUSTRATIONS,
  payload: relations
});

export const addZillIllustrationAction = (parentKey, zillIllustration, goalHrefsArray) => ({
  type: ACTION_TYPES.ADD_ZILL_ILLUSTRATION,
  payload: {
    parentKey,
    zillIllustration: {
      key: uuidv4(),
      ...zillIllustration,
    },
    zillIllustrationRelationKey: uuidv4(),
    goals: goalHrefsArray.map((goalHref) => ({
      href: goalHref,
      relationKey: uuidv4(),
    })),
  }
});

export const removeZillIllustrationRelationAction = (key, relationKey) => ({
  type: ACTION_TYPES.REMOVE_ZILL_ILLUSTRATION_RELATION,
  payload: {
    key,
    relationKey
  }
});

export const expandZillGoalSelectionsAction = (goalHrefs) => ({
  type: ACTION_TYPES.EXPAND_ZILL_GOAL_SELECTIONS,
  payload: goalHrefs
});

export const setExpandedZillGoalSelectionsAction = (goals) => ({
  type: ACTION_TYPES.SET_EXPANDED_ZILL_GOAL_SELECTIONS,
  payload: goals
});

export const getAllOfTypeAction = (params, options) => ({
  type: ACTION_TYPES.GET_ALL_OF_TYPE,
  payload: { params, options }
});

export const getReferenceFrameAction = (params) => ({
  type: ACTION_TYPES.GET_REFERENCE_FRAME,
  payload: params
});

export const setReferenceFrameAction = (key, results) => ({
  type: ACTION_TYPES.SET_REFERENCE_FRAME,
  payload: { key, results }
});

export const setAllOfTypeAction = (results) => ({
  type: ACTION_TYPES.SET_ALL_OF_TYPE,
  payload: results
});

export const loadTermReferencesAction = (key) => ({
  type: ACTION_TYPES.LOAD_TERM_REFERENCES,
  payload: key
});

export const setTermReferencesAction = (results) => ({
  type: ACTION_TYPES.SET_TERM_REFERENCES,
  payload: results
});

export const addTermReferenceAction = (term) => ({
  type: ACTION_TYPES.ADD_TERM_REFERENCE,
  payload: term
});

export const removeTermReferenceAction = (href) => ({
  type: ACTION_TYPES.REMOVE_TERM_REFERENCE,
  payload: href
});

export const loadExternalDocumentSectionsAction = (key) => ({
  type: ACTION_TYPES.LOAD_EXTERNAL_DOCUMENT_SECTIONS,
  payload: key
});

export const setExternalDocumentSectionsAction = (results) => ({
  type: ACTION_TYPES.SET_EXTERNAL_DOCUMENT_SECTIONS,
  payload: results
});

export const removeReferenceGroupReferencesAction = (key) => ({
  type: ACTION_TYPES.REMOVE_REFERENCE_GROUP_REFERENCES,
  payload: key
});

export const addAttachments = (documentKey, image) => ({
  type: ACTION_TYPES.ADD_ATTACHMENTS,
  payload: {
    documentKey,
    attachments: [
      {
        key: uuidv4(),
        type: image.type,
        name: `${image.type}-710x533-${image.file.name}`,
        width: 710,
        height: 533,
        $$base64: image.original,
        $$mimeType: image.file.type,
        isNew: true,
        resized: true
      },
      {
        key: uuidv4(),
        type: image.type,
        name: `${image.type}-${image.file.name}`,
        $$base64: image.original,
        $$mimeType: image.file.type,
        isNew: true
      }
    ]
  }
});

export const addAttachment = (documentKey, attachment, file, newNode, parentKey) => ({
  type: ACTION_TYPES.ADD_ATTACHMENTS,
  payload: {
    documentKey: documentKey,
    parentKey,
    attachments: [{
      key: uuidv4(),
      ...attachment,
      file: file
    }],
    newNode
  }
});

export const removeAttachment = (documentKey, attachmentKey, onlyRemoveImage, attachmentFileName) => ({
  type: ACTION_TYPES.REMOVE_ATTACHMENTS,
  payload: {
    documentKey,
    attachmentKeys: [attachmentKey],
    onlyRemoveImage,
    attachmentFileName
  }
});

export const removeAttachments = (documentKey, attachmentKeys) => ({
  type: ACTION_TYPES.REMOVE_ATTACHMENTS,
  payload: {
    documentKey,
    attachmentKeys
  }
});

export const changeMode = (mode) => ({
  type: ACTION_TYPES.CHANGE_MODE,
  payload: mode
});

export const toggleSubmitSuggestionsOfGroupAction = (group, isSelected) => ({
  type: ACTION_TYPES.TOGGLE_SUBMIT_SUGGESTIONS_OF_GROUP,
  payload: {
    groupKey: group.key,
    isSelected
  }
});

export const submitSuggestionsAction = (message, url) => ({
  type: ACTION_TYPES.SUBMIT_SUGGESTIONS,
  payload: {
    message,
    url
  }
});

export const suggestionsSubmittedAction = (correspondingProposals) => ({
  type: ACTION_TYPES.SUGGESTIONS_SUBMITTED,
  payload: { correspondingProposals }
});

export const acceptSuggestionsAction = () => ({
  type: ACTION_TYPES.ACCEPT_SUGGESTIONS,
});

export const suggestionsAcceptedAction = () => ({
  type: ACTION_TYPES.SUGGESTIONS_ACCEPTED
});

export const rejectSuggestionsAction = () => ({
  type: ACTION_TYPES.REJECT_SUGGESTIONS
});

export const suggestionsRejectedAction = () => ({
  type: ACTION_TYPES.SUGGESTIONS_REJECTED
});

export const cancelSuggestionsAction = () => ({
  type: ACTION_TYPES.CANCEL_SUGGESTIONS
});

export const suggestionsCancelledAction = () => ({
  type: ACTION_TYPES.SUGGESTIONS_CANCELLED
});

export const hoverProposalLineAction = (key, screen) => ({
  type: ACTION_TYPES.HOVER_PROPOSAL_LINE,
  payload: {
    key,
    screen
  }
});

export const clearHoverProposalLineAction = (key, screen) => ({
  type: ACTION_TYPES.CLEAR_HOVER_PROPOSAL_LINE,
  payload: {
    key,
    screen
  }
});

export const closeAsideAction = () => ({
  type: ACTION_TYPES.CLOSE_ASIDE
});

export const openSubmitSuggestionsModalAction = () => ({
  type: ACTION_TYPES.OPEN_SUBMIT_SUGGESTIONS_MODAL
});

export const closeSubmitSuggestionsModalAction = () => ({
  type: ACTION_TYPES.CLOSE_SUBMIT_SUGGESTIONS_MODAL
});

export const openReviewSuggestionsModalAction = (reviewAction) => ({
  type: ACTION_TYPES.OPEN_REVIEW_SUGGESTIONS_MODAL,
  payload: {
    reviewAction,
  },
});

export const closeReviewSuggestionsModalAction = () => ({
  type: ACTION_TYPES.CLOSE_REVIEW_SUGGESTIONS_MODAL
});

export const sendEmailOfSubmittedSuggestionsAction = (suggestions) => ({
  type: ACTION_TYPES.SEND_SUBMITTED_SUGGESTIONS_EMAIL,
  payload: {
    key: uuidv4(),
    suggestions
  }
});

export const calculateSuggestionstoSubmitAction = () => ({
  type: ACTION_TYPES.CALCULATE_SUGGESTIONS_TO_SUBMIT
});

export const calculateSuggestionsToReviewAction = () => ({
  type: ACTION_TYPES.CALCULATE_SUGGESTIONS_TO_REVIEW
});

export const addImageInGroupAction = (parentKey, attachment) => ({
  type: ACTION_TYPES.ADD_IMAGE_IN_GROUP,
  payload: {
    key: uuidv4(),
    relationKey: uuidv4(),
    attachmentKey: uuidv4(),
    type: 'IMAGE',
    parentKey,
    attachment
  }
});

export const backToDocumentsListAction = () => ({
  type: ACTION_TYPES.BACK_TO_DOCUMENTS_LIST
});

export const proposedDeletionFailedAction = () => ({
  type: ACTION_TYPES.PROPOSED_DELETION_VALIDATION_ERROR
});

export const addReferenceFromNodeAction = (parentKey, resourceHref, description) => ({
  type: ACTION_TYPES.ADD_REFERENCE_FROM_NODE,
  payload: {
    parentKey,
    resourceHref,
    referenceNode: {
      key: uuidv4(),
      description
    },
    relationToNode: {
      key: uuidv4()
    },
    referenceRelation: {
      key: uuidv4()
    }
  }
});

export const addAttachmentToNodeAction = (
  parentKey, attachmentNode, attachment, file
) => ({
  type: ACTION_TYPES.ADD_ATTACHMENT_TO_NODE,
  payload: {
    parentKey,
    attachmentNode: {
      key: uuidv4(),
      ...attachmentNode
    },
    attachment: {
      key: uuidv4(),
      ...attachment,
      file: file
    },
    relationToNode: {
      key: uuidv4()
    },
    newGroupKey: uuidv4(),
    newGroupRelationKey: uuidv4()
  }
});

export const addGlobalDocumentRelationAction = (parentKey, globalDocument, isGlobalAttachmentGroup) => ({
  type: ACTION_TYPES.ADD_GLOBAL_DOCUMENT_RELATION,
  payload: {
    parentKey,
    globalDocument,
    isGlobalAttachmentGroup,
    relationToNode: {
      key: uuidv4()
    },
    newGroupKey: uuidv4(),
    newGroupRelationKey: uuidv4()
  }
});

export const editGlobalDocumentRelationAction = (parentKey, globalDocument, previousRelation, isGlobalAttachmentGroup) => ({
  type: ACTION_TYPES.EDIT_GLOBAL_DOCUMENT_RELATION,
  payload: {
    parentKey,
    globalDocument,
    previousRelation,
    isGlobalAttachmentGroup
  }
});

export const replaceGlobalDocumentRelationWithAttachmentAction = (
  parentKey, previousGlobalDocumentRelationKey, attachmentNode, attachment, file
) => ({
  type: ACTION_TYPES.REPLACE_GLOBAL_DOCUMENT_RELATION_WITH_ATTACHMENT,
  payload: {
    parentKey,
    previousGlobalDocumentRelationKey,
    attachmentNode: {
      key: uuidv4(),
      ...attachmentNode
    },
    attachment: {
      key: uuidv4(),
      ...attachment,
      file: file
    },
    relationToNode: {
      key: uuidv4()
    },
    newGroupKey: uuidv4(),
    newGroupRelationKey: uuidv4()
  }
});

export const publishDocumentAction = (proposal, message, locationUrl) => ({
  type: ACTION_TYPES.PUBLISH_DOCUMENT,
  payload: {
    proposal,
    message,
    locationUrl,
  }
});

export const sendDocumentPublishedMailAction = (publishedDocument) => ({
  type: ACTION_TYPES.SEND_PUBLISHED_EMAIL,
  payload: {
    key: uuidv4(),
    publishedDocument
  }
});

export const documentPublishedAction = () => ({
  type: ACTION_TYPES.DOCUMENT_PUBLISHED
});

export const openedPublishModal = () => ({
  type: ACTION_TYPES.OPENED_PUBLISH_MODAL
});

export const closePublishModal = () => ({
  type: ACTION_TYPES.CLOSE_PUBLISH_MODAL
});

export const lastReadMarkUpdatedAction = () => ({
  type: ACTION_TYPES.LAST_READ_MARK_UPDATED
});

export const importDocumentInSectionAction = (importResult, parentKey) => ({
  type: ACTION_TYPES.IMPORT_DOCUMENT_IN_SECTION,
  payload: {
    importResult,
    parentKey
  }
});

export const refreshNewsletter = () => ({
  type: ACTION_TYPES.REFRESH_NEWSLETTER
});

export const setNewsletterSettings = (newsletterSettings) => ({
  type: ACTION_TYPES.SET_NEWSLETTER_SETTINGS,
  payload: {
    newsletterSettings
  }
});

export const addTeaser = (sectionKey, position, teaser) => ({
  type: ACTION_TYPES.ADD_TEASER,
  payload: {
    sectionHref: `/content/${sectionKey}`,
    position,
    teaser
  }
});


export const patchDateToSendAction = (dateToSend) => ({
  type: ACTION_TYPES.PATCH_DATE_TO_SEND,
  payload: {
    dateToSend
  }
});

export const patchThemes = (key, themes) => ({
  type: ACTION_TYPES.PATCH_THEMES,
  payload: {
    key,
    themes
  }
});

export const validateExternalRelationAction = (permalink, title, type, isUniqueInDienstverleningKov) => ({
  type: ACTION_TYPES.VALIDATE_EXTERNAL_RELATION,
  payload: {
    permalink,
    title,
    type,
    isUniqueInDienstverleningKov
  }
});

export const openSelectExternalRelationModalAction = () => ({
  type: ACTION_TYPES.OPEN_SELECT_EXTERNAL_RELATION_MODAL
});

export const openNotApplicableProposalsModal = () => ({
  type: ACTION_TYPES.OPEN_NOT_APPLICABLE_PROPOSALS_MODAL
});

export const closeNotApplicableProposalsModal = () => ({
  type: ACTION_TYPES.CLOSE_NOT_APPLICABLE_PROPOSALS_MODAL
});

export const removeSelections = () => ({
  type: ACTION_TYPES.REMOVE_SELECTIONS
});

export const loadPreviousVersionAction = (key, hrefs) => ({
  type: ACTION_TYPES.LOAD_PREVIOUS_VERSION,
  payload: { key, hrefs }
});

export const setSecondaryEducationTypes = (secondaryEducationTypes) => ({
  type: ACTION_TYPES.SET_SECONDARY_EDUCATION_TYPES,
  payload: {
    secondaryEducationTypes
  }
});

export const patchSecondaryEducationTypes = (key, secondaryEducationTypes) => ({
  type: ACTION_TYPES.PATCH_SECONDARY_EDUCATION_TYPES,
  payload: {
    key,
    secondaryEducationTypes
  }
});

export const initWebFacets = (config) => ({
  type: ACTION_TYPES.INIT_WEB_FACETS,
  payload: {
    config,
  },
});

export const patchExpiryDate = (key, expiryDate) => ({
  type: ACTION_TYPES.PATCH_EXPIRY_DATE,
  payload: {
    key,
    expiryDate,
  },
});
