import { loadReferenceFrame } from '@newStore/referenceFrames/referenceFramesState';
import { referenceFrames } from '@newStore/referenceFrames/referenceFrames';
import * as ACTION_TYPES from '@store/constants/actionTypes';
import { RequiredType, RootNodeConfig, NodeType } from '@nodeTypeConfig/configTypes';
import { getAllOfResource, pathMap } from '@newStore/externalData/externalDataState';
import { ContentType } from '@generalTypes/apiTypes';
import { settings } from '../../settings';
import {
  identifierComponent,
  titleComponent,
  publishComponent,
} from '../helpers/genericComponents';
import { educationalPointersComponent } from './llinkidGenericConfigProps';

const llinkidCurriculum: RootNodeConfig = {
  information: {
    definiteArticle: true,
    single: 'Leerplan secundair',
    plural: 'Leerplannen secundair',
    icon: require('../../../../img/icons/llinkid.svg'),
    category: 'LEERPLAN',
  },
  node: null, // will be overwritten below
  preloadActions: [
    {
      type: ACTION_TYPES.GET_SECONDARY_EDUCATION_TYPES,
    },
  ],
  onLoadActions: [
    loadReferenceFrame({ referenceFrame: referenceFrames.educationalPointers }),
    loadReferenceFrame({ referenceFrame: referenceFrames.educationalComponents }),
    getAllOfResource({ resource: pathMap.studyProgrammes, refresh: false }),
    getAllOfResource({ resource: pathMap.studyProgrammeGroups, refresh: false }),
  ],
  createDefaults: {
    realisationPeriod: 24,
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
  },
  createChildDefaults: [
    {
      type: ContentType.LLINKID_GUIDING_PRINCIPLE_GROUP,
      readorder: 1,
    },
    {
      type: ContentType.LLINKID_CONTEXT,
      readorder: 2,
    },
    {
      type: ContentType.LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT,
      readorder: 3,
    },
    {
      type: ContentType.LLINKID_GOAL_LIST,
      readorder: 4,
    },
    {
      type: ContentType.LLINKID_BASIC_MATERIAL_REQUIREMENTS,
      readorder: 5,
    },
    {
      type: ContentType.LLINKID_CORRELATION_ENDTERMS,
      readorder: 6,
    },
  ],
  edit: [
    {
      component: 'foundational',
      label: 'Gemeenschappelijk',
      type: 'checkbox',
    },
    {
      ...identifierComponent,
      required: RequiredType.ERROR,
      width: 2,
    },
    {
      ...titleComponent,
      width: 8,
    },
    {
      component: 'version',
      label: 'Versie',
      width: 2,
      readonly: true,
    },
    {
      component: 'previousVersion',
      label: 'Vervangt (vorige versie)',
      readonly: true,
    },
    {
      ...publishComponent,
      width: 4,
    },
    {
      component: 'realisationPeriod',
      label: 'Realisatieperiode',
      width: 4,
      options: {
        schoolyears: 2,
      },
      required: RequiredType.ERROR,
    },
    {
      component: 'expiryDate',
      label: 'Vervaldatum',
      width: 4,
    },
    {
      component: 'validityPeriod',
      label: 'Van toepassing',
      width: 12,
      readonly: true,
    },
    {
      component: 'applicability',
      property: 'applicability.studyProgrammes',
      required: RequiredType.ERROR,
      customRequiredMessage: 'Je moet minimum 1 <strong>studierichting</strong> kiezen',
      type: 'select',
      label: 'In welke studierichtingen is het leerplan van toepassing?',
      options: {
        clearAllAllowed: true,
        selectAllAllowed: false,
        placeholder: 'Study programmes...',
        subfield: 'studyProgrammes',
      },
    },
    {
      component: 'curriculumTheme',
      property: 'themes',
      definiteArticle: true,
      label: 'Leerplanthema',
      required: RequiredType.ERROR,
    },
    {
      component: 'secondaryEducationTypes',
      definiteArticle: true,
      label: 'Soort vorming',
      required: RequiredType.ERROR,
    },
    educationalPointersComponent,
    {
      component: 'educationalComponents',
      type: 'llinkidReferences',
      label: 'Vormingscomponenten',
      options: {
        referenceFrame: 'EDUCATIONAL_COMPONENTS',
      },
    },
    {
      component: 'image',
      property: 'attachments',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
  ],
  tocTypes: [
    NodeType.LLINKID_GUIDING_PRINCIPLE_GROUP,
    NodeType.LLINKID_GOAL_SECTION,
    NodeType.LLINKID_GOAL_LIST,
    NodeType.LLINKID_CORRELATION_ENDTERMS,
    NodeType.LLINKID_CONTEXT,
    NodeType.LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT,
    NodeType.LLINKID_BASIC_MATERIAL_REQUIREMENTS,
    NodeType.SECTION,
    NodeType.SECTION_NEW,
  ],
  customEditorOptions: {
    showMarkerButton: true,
    hideTermButton: false,
  },
  buildingBlocks: [],
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  previewModes: [
    {
      type: 'URL',
      name: 'LLinkID',
      location: `${settings.apisAndUrls.llinkid}/#!/leerplan/{%key}/doelenlijst?preview=true`,
    },
  ],
  disableDeleteWhenIssued: true,
  allowSuggestions: false,
};

export const LLINKID_CURRICULUM: RootNodeConfig = {
  ...llinkidCurriculum,
  node: { type: ContentType.LLINKID_CURRICULUM, foundational: false },
};

export const LLINKID_FOUNDATIONAL_CURRICULUM: RootNodeConfig = {
  ...llinkidCurriculum,
  node: { type: ContentType.LLINKID_CURRICULUM, foundational: true },
  isSearchable: false,
  isCreatable: false,
};
