/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class ContactPersonPicker {
  constructor($scope, $ngRedux, searchApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.searchApi = searchApi;

    this.resourcePickerAuthorTemplate = require('../../../../screen/optionTemplates/authorOption.html');
    this.searchParameters = {
      expand: 'SUMMARY',
      types: 'person',
      'responsibilities.organisationalUnit.root': '/organisations/c000eaea-c451-2590-e044-d4856467bfb8',
      limit: 30,
      highlight: 'false'
    };
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const selectedContacts =
        this.selectedContactsAfterUserChange ||
        state.document.viewModel.aside.editDocument.selectedContacts;

      return {
        contacts: {
          ...state.document.viewModel.aside.editDocument.contacts,
          key: 'contacts'
        },
        selectedContacts,
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(e, selection, newSelectedContactsList) {
    // when the user adds a new contact it is shown immediately in the UI (KovResourcePicker native functionality) but the state is updated multiple time before it is added to the selectedContacts array. For that reason, if we only listen to the state to update the value and we don't do the following, the new contact added to the list will:
    // 1) appear, because of the KovResourcePicker
    // 2) disappear, because we receive a new state on the connect that it doesn't have the new value yet
    // 3) appear again, because the new contact gets added to the state
    // Then we do the following to avoid this "flickering" effect
    this.selectedContactsAfterUserChange = newSelectedContactsList;
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(this.editKey, { contacts: newSelectedContactsList.map(s => s.$$meta.permalink) })
    );
  }

  filterKovResourcePickerSearchApiResponse(searchApiContactsList) {
    // The "this" (context) here is the kovResoursePicker, so it has a model
    const selectedContactsHrefs = this.model.map((a) => a.$$meta.permalink);
    return searchApiContactsList.filter((a) => !selectedContactsHrefs.includes(a.$$meta.permalink));
  }

  contact2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return resource.firstName + ' ' + resource.lastName;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }
    }
    return '<NONE>';
  }
}

export default {
  template: require('./asideContactPerson.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: ContactPersonPicker
};
