/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { getBase64, getImage } from '../../../reduxLoop/helpers/documentHelpers';
import uuidv4 from 'uuid/v4';

class asideImage {
  constructor($scope, $ngRedux, searchApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.searchApi = searchApi;

    this.resourcePickerAuthorTemplate = require('../../../screen/optionTemplates/authorOption.html');
    this.searchParameters = {
      expand: 'SUMMARY',
      types: 'PERSON,CVO,SCHOOL,SCHOOLCOMMUNITY,CLB,ORGANISATION,BOARDING,GOVERNINGINSTITUTION',
      highlight: 'false'
    };
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const nodeVM = state.document.viewModel.aside.editDocument;
      this.image = { ...(getImage(nodeVM.$$attachments, this.sConfig.options.type) || {}) };
      const resizedImage = { ...(getImage(nodeVM.$$attachments, this.sConfig.options.type, 800) || {}) };

      return {
        attachments: nodeVM.attachments,
        image: {
          ...this.image
        },
        resizedImage,
        allImages: nodeVM.attachments?.value.filter(a => a.type === this.sConfig.options.type),
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly
      };
    })(this);

    if (this.image) {
      this.imageDescription = this.image.description;
      this.imageAlt = this.image.alt;
    }
  }

  $onDestroy() {
    this.unsubscribe();
  }

  showField(field) {
    return this.sConfig.options.fields && this.sConfig.options.fields.includes(field);
  }

  updatedField(field) {
    const patch = {
      type: this.sConfig.options.type
    };
    patch[field.toLowerCase()] = this[`image${field}`];
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAttachmentAction(this.editKey, this.image.key, patch)
    );
  }

  changeRightsHolder(rightsHolder) {
    let changed = false;

    // console.log('CHANGE rightsholder:',rightsHolder)

    let newAttachments = [...(this.$parent.ctrl.attachments?.value || [])];
    let attachment = newAttachments.find(a => a.type === this.$parent.ctrl.sConfig.options.type);

    if (attachment) {
      if (rightsHolder) {
        attachment.rightsHolder = { href: rightsHolder.$$meta.permalink };
        changed = true;
      } else if (!rightsHolder && attachment && attachment.rightsHolder) {
        delete attachment.rightsHolder;
        changed = true;
      }
    }

    if (changed) {
      // TODO this is incorrect?
      this.$parent.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeAttachment(this.$parent.ctrl.editKey, {
        attachments: this.$parent.ctrl.attachments.value
      }));
    }
  }

  async imageCroppedChanged(e, data) {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addAttachments(this.editKey, data));
    this.image = data.original;
  }

  async imageChanged(e, data) {
    let base64 = await getBase64(data.file);
    let newAttachment = {
      key: this.image.key || uuidv4(),
      newKey: uuidv4(),
      type: data.type,
      name: data.file.name,
      size: data.file.size,
      $$base64: base64,
      isNew: true
    };

    if (this.imageAlt) {
      newAttachment.alt = this.imageAlt;
    }
    if (this.image.rightsHolder) {
      newAttachment.rightsHolder = { href: this.image.rightsHolder };
    }
    if (this.imageDescription) {
      newAttachment.description = this.imageDescription;
    }

    // action to update state.apiPendingUpload and then patchNode
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.addAttachment(
        this.editKey,
        newAttachment,
        data.file
      )
    );
    this.image = newAttachment;
  }

  imageRemoved() {
    if (this.sConfig.options.crop) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeAttachments(this.editKey, this.attachments.value.map(a => a.key)));
    } else {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeAttachments(this.editKey, this.allImages.map(a => a.key), true));
    }
  }

  author2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return resource.firstName + ' ' + resource.lastName;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }
    }
    return '<NONE>';
  }

  // generate deleted proposal line but vm does not add proposal object so we will have to fix this when we took over proposalVm
  /* proposalLineStyle(row) {
    if (row.$$meta.deleted) {
      // const line = row.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      // const color = row.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = row.$$meta.deleted ? ' is-deleted' : '';
      // const isReviewing = row.proposal.isReviewingMode ? ' is-reviewing' : '';
      // return line + ' ' + color + isDeleted + isReviewing;
      return 'not-submitted' + ' ' + 'same-user' + isDeleted + '';
    }
    return '';
  } */
}

export default {
  template: require('./asideImage.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<'
  },
  controller: asideImage
};
