import { setFilterProperty } from '@newStore/documentList/newDocumentListState';
import * as constants from '@store/constants/constants';
import { cloneDeep } from 'lodash';
import { IScope } from 'angular';
import ngRedux from 'ng-redux';
import { SearchParams } from '@newStore/documentList/newDocumentListTypes';
import { selectUser } from '@newStore/user/userSelectors';
import { selectUserVmForDocumentList } from '@newStore/documentList/newDocumentListSelectors';
import { RootState } from '@generalTypes/rootStateTypes';
require('./filtersBar.scss');

class filtersBar {
  private $scope: IScope;
  private $ngRedux: ngRedux.INgRedux;
  private search: SearchParams = {};
  private types: any;
  private q: any;
  private $parent: any;
  private loggedUser: any;
  // @ts-expect-error
  private searchApi: any;
  // @ts-expect-error
  private contentApi: any;
  // @ts-expect-error
  private unsubscribe?: () => void;

  static $inject = ['$scope', '$ngRedux', 'searchApi', 'contentApi'];

  constructor($scope, $ngRedux, searchApi, contentApi) {
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.$scope.resourcePickerAuthorTemplate = require('../../../screen/optionTemplates/authorOption.html');
    this.$scope.resourcePickerThemeTemplate = require('../../../screen/optionTemplates/themeOption.html');
    this.searchApi = searchApi;
    this.contentApi = contentApi;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state: RootState) => {
      const security = selectUserVmForDocumentList(state);
      return {
        search: state.newDocumentList.searchParams, // angular modifies the searchParams directly with the checkbox. cloneDeep avoids modifying the state
        types: cloneDeep(state.newDocumentList.searchParams?.types),
        q: state.newDocumentList.searchParams?.q,
        theme: state.newDocumentList.searchParams?.theme,
        issued: state.newDocumentList.searchParams?.issued,
        modifiedFrom: state.newDocumentList.searchParams?.modifiedFrom,
        modifiedTo: state.newDocumentList.searchParams?.modifiedTo,
        author: state.newDocumentList.searchParams?.author,
        groupedSearchables: security.searchCategories,
        queryAuthorsParameters: constants.allAuthorsParameters,
        queryThemesParameters: constants.queryThemesParameters,
        loggedUser: selectUser(state),
      };
    })(this);
    this.$scope.$emit('do_search', this.search);
  }

  doSearch($event) {
    if ($event.type === 'documenttype') {
      this.$ngRedux.dispatch(setFilterProperty({ types: this.types, q: this.q }));
    } else if ($event.type === 'click' || ($event.type === 'keypress' && $event.keyCode === 13)) {
      this.$ngRedux.dispatch(setFilterProperty({ q: this.q }));
    }
  }

  issuedChanged(event, date) {
    this.$ngRedux.dispatch(setFilterProperty({ issued: date }));
  }

  modifiedFromChanged(event, date) {
    this.$ngRedux.dispatch(setFilterProperty({ modifiedFrom: date }));
  }

  modifiedToChanged(event, date) {
    this.$ngRedux.dispatch(setFilterProperty({ modifiedTo: date }));
  }

  setUserAsAuthor() {
    this.$ngRedux.dispatch(setFilterProperty({ author: this.loggedUser }));
  }

  authorSelected(author) {
    if ((author || this.$parent.ctrl.search.author) && author !== this.$parent.ctrl.search.author) {
      this.$parent.ctrl.$ngRedux.dispatch(setFilterProperty({ author }));
    }
  }

  themeSelected(theme) {
    if ((theme || this.$parent.ctrl.search.theme) && theme !== this.$parent.ctrl.search.theme) {
      this.$parent.ctrl.$ngRedux.dispatch(setFilterProperty({ theme }));
    }
  }

  author2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return resource.firstName + ' ' + resource.lastName;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }
    }
    return '<NONE>';
  }

  theme2String(t) {
    return t ? t.title : '<NONE>';
  }
}

export default {
  template: require('./filtersBar.html'),
  controllerAs: 'ctrl',
  bindings: {},
  controller: filtersBar,
};
