// import { isResourceSupported } from '../reduxLoop/helpers/documentHelpers';
import { isValidExternalUrl } from '@newStore/genericHelpers';
import constants from '../reduxLoop/constants/constants';

/**
 * funtion duplicated from documentHelpers.js to avoid dependency cycles
 */
const isResourceSupported = (url) => {
  const supportedResources = [
    '/content/',
    '/events/',
    '/persons/',
    '/training/modules/',
    '/web/sites/',
    '/websites/',
  ];
  return supportedResources.some((resource) => url.startsWith(resource));
};

export const isLinkedContentValid = (node, state) => {
  if (state.viewModel.loading) return true;

  const reference = node.$$children.find((c) => c);
  const linkedContentType = state.linkedContentTypes.get(node.key);
  if (
    !reference &&
    linkedContentType &&
    linkedContentType !== constants.teaserLinkOptions.NOT_LINKED.name
  ) {
    return {
      type: 'ERROR',
      message: 'components.teaserLinkedContent.error.linkedcontentNoLinkSet',
    };
  }

  if (!reference) return true;

  const relations = state.apiWithPendingChanges.contentRelations.from[`/content/${reference.key}`];
  const relation = relations.find((r) => r.relationtype === 'REFERENCES');
  if (!isResourceSupported(relation.to.href) && !isValidExternalUrl(relation.to.href)) {
    return {
      type: 'ERROR',
      message: 'components.teaserLinkedContent.error.invalidExternalUrl',
    };
  }

  if (!reference.title) {
    return {
      type: 'ERROR',
      message: 'components.teaserLinkedContent.error.linkedcontentNoCallToActionSet',
    };
  }

  if (reference.title.split(' ').length > 5) {
    return {
      type: 'ERROR',
      message: 'components.teaserLinkedContent.error.linkedcontentCallToActionLength'
    };
  }

  return true;
};
